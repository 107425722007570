import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useApi } from "../../providers/ApiContext";
import { JsonEditor } from 'jsoneditor-react18';
import {
    Paper,
    Box,
    Chip,
    Divider,
    CircularProgress,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { toast } from 'react-toastify';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { format } from 'date-fns';
import { useProjects } from "../../providers/ProjectsProvider";

const LATEST_LOGS_POLL_COUNTDOWN_SECONDS = 5;

function ProjectViewLogsComponent({ projectId }) {
    const navigate = useNavigate();
    const { axiosInstance } = useApi();
    const { setSelectedDuplicateEndpoint, getProjectById } = useProjects();
    const project = getProjectById(projectId);
    const [isProjectLogsLoading, setIsProjectLogsLoading] = useState(false);
    const [projectLogs, setProjectLogs] = useState([]);
    const [initialProjectLogsRequestCompleted, setInitialProjectLogsRequestCompleted] = useState(false);
    const [lastLogId, setLastLogId] = useState(null);
    const [isLastPage, setIsLastPage] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [countdown, setCountdown] = useState(LATEST_LOGS_POLL_COUNTDOWN_SECONDS);
    const [intervalId, setIntervalId] = useState(null);

    const handleOpenDialog = (log) => {
        setSelectedLog(log);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const convertRequestLogToMockEndpoint = (log) => {
        const convertedMockEndpoint = {
            name: '',
            description: '',
            targetEndpointRequestType: log.method,
            targetEndpointUrl: log.endpoint.split('?')[0],
            urlParametersCriteria: log.endpoint
                .split('?')[1]
                ?.split('&')
                .map(param => ({ description: '', parameterKey: param.split('=')[0], expectedParameterValue: param.split('=')[1] })) || [],
            headersCriteria: Object.entries(log.requestHeaders).map(([key, value]) => (
                {
                    description: '',
                    headerKey: key,
                    expectedHeaderValue: value,
                    isJWT: false,
                    jwtCriteria: [],
                }
            )),
            mockResponseHttpStatus: log.responseStatus,
            mockResponsePayload: log.responseBody,
            mockResponseHeaders: Object.entries(log.responseHeaders)
                .filter(([key, value]) => typeof value === 'string')
                .map(([key, value]) => (
                    {
                        key,
                        value,
                    }
                )),
            hitsLimit: -1,
            hitsCount: 0,
            priority: 1,
            isEnabled: true,
        };

        return convertedMockEndpoint;
    };

    const handleMockLogRequest = (log) => {
        const convertedMockEndpoint = convertRequestLogToMockEndpoint(log);
        setSelectedDuplicateEndpoint(convertedMockEndpoint);
        navigate(`/project/${projectId}/endpoints/create`);
    };

    const fetchProjectLogs = async () => {
        if (isLastPage) {
            return;
        }
        try {
            setIsProjectLogsLoading(true);
            const response = await axiosInstance.get(`/v1/admin/project/${projectId}/log/request?limit=10&lastId=${lastLogId ?? ''}`);
            if (lastLogId === null && !isLastPage) {
                setProjectLogs(response.data.data);
            } else {
                setProjectLogs([...projectLogs, ...response.data.data]);
            }
            setIsLastPage(response.data.nextPageCursor === null);
            setLastLogId(response.data.nextPageCursor);
            setIsProjectLogsLoading(false);
            setInitialProjectLogsRequestCompleted(true);
        } catch (error) {
            setIsProjectLogsLoading(false);
            toast.error('Loading project\'s logs failed, please try again later.');
        }
    };

    const startLatestProjectLogsCountdown = async () => {
        // Clear existing interval if any
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        // Reset countdown
        setCountdown(LATEST_LOGS_POLL_COUNTDOWN_SECONDS);

        // Start a new interval for fetchLatestProjectLogs and countdown
        const newIntervalId = setInterval(() => {
            setCountdown(prevCountdown => {
                if (!project.isRecordModeEnabled) {
                    // Stop poll if Record Mode is disabled
                    return;
                }
                if (prevCountdown === 1) {
                    // Reset countdown and fetch latest logs when countdown reaches 0
                    fetchLatestProjectLogs();
                    return LATEST_LOGS_POLL_COUNTDOWN_SECONDS;
                }
                // Decrement countdown otherwise
                return prevCountdown - 1;
            });
        }, 1000); // Update every second

        setIntervalId(newIntervalId);
    }

    const fetchLatestProjectLogs = async () => {
        try {
            const response = await axiosInstance.get(`/v1/admin/project/${projectId}/log/request/latest?latestId=${projectLogs.length == 0 ? '' : projectLogs[0]._id}`);
            setProjectLogs([...response.data, ...projectLogs]);
        } catch (error) {
            toast.error('Loading latest project\'s logs failed, please refresh this page to get the latest ones.');
        }
    };

    const clearProjectLogs = async () => {
        try {
            setIsProjectLogsLoading(true);
            await axiosInstance.post(`/v1/admin/project/${projectId}/log/request/clear`);
            refreshProjectLogs();
        } catch (error) {
            setIsProjectLogsLoading(false);
            toast.error('Clear project\'s logs failed, please try again later.');
        }
    };

    const refreshProjectLogs = async () => {
        setLastLogId(null);
        setIsLastPage(false);
        setProjectLogs([]);
        setRefreshCounter(count => count + 1);
    };

    useEffect(() => {
        fetchProjectLogs();
    }, [refreshCounter]);

    useEffect(() => {
        if (initialProjectLogsRequestCompleted) {
            startLatestProjectLogsCountdown();
        }
    }, [initialProjectLogsRequestCompleted, project.isRecordModeEnabled]);

    // Cleanup interval on component unmount
    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    return (
        <Paper elevation={1} style={{ padding: 30 }}>
            <Box
                sx={{ marginBottom: 4 }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Button variant="outlined" onClick={() => refreshProjectLogs()}>Refresh</Button>
                <Button variant="outlined" onClick={() => clearProjectLogs()} sx={{ marginLeft: 2 }}>Clear</Button>
            </Box>

            <Divider><Chip variant="outlined" label={!project.isRecordModeEnabled ? 'Logs' : `Refreshing in ${countdown} seconds...`} sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>

            {projectLogs.length === 0 ? (
                <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ marginTop: 2 }}>
                    <Grid item xs={12} textAlign="center">
                        <img
                            src="/empty-box.png"
                            alt=""
                            height={150}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h5">
                            No logs yet
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography>
                            You must enable Record Mode and use the proxy for the logs to appear here.
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Timeline>
                        {projectLogs.map((projectLog) => (
                            <TimelineItem key={projectLog._id} position={projectLog.isMocked ? 'left' : 'right'}>
                                <TimelineOppositeContent color="text.secondary">
                                    <Typography variant="body1" color="text.secondary">{format(new Date(projectLog.createdAt), 'dd MMMM yyyy')}</Typography>
                                    <Typography variant="body1" color="text.secondary">{format(new Date(projectLog.createdAt), 'HH:mm')}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" color={projectLog.responseStatus < 300 ? "success" : projectLog.responseStatus >= 500 ? "error" : "warning"} />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography variant="body1" color="text.primary">{projectLog.method} <i>{projectLog.endpoint.split('?')[0]}</i></Typography>
                                    <Typography variant="body1" color="text.secondary">Status: {projectLog.responseStatus} ● {projectLog.isMocked ? 'Mocked Request' : 'Proxied Request'}</Typography>
                                    <Button variant="outlined" onClick={() => handleOpenDialog(projectLog)}>Details</Button>
                                    {!projectLog.isMocked ? (
                                        <Button variant="outlined" onClick={() => handleMockLogRequest(projectLog)} sx={{ marginLeft: 2 }}>Mock</Button>
                                    ) : null}
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>

                    {selectedLog && (
                        <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleCloseDialog} aria-labelledby="log-details-title">
                            <DialogTitle id="log-details-title">Log Details</DialogTitle>
                            <DialogContent dividers={true} style={{ maxHeight: '600px', overflowY: 'auto' }}> {/* Adjust maxHeight as needed */}
                                <DialogContentText>
                                    <Typography variant="body1" color="text.primary">Endpoint: {selectedLog.endpoint}</Typography>
                                    <Typography variant="body1" color="text.primary">Method: {selectedLog.method}</Typography>
                                    <Typography variant="body1" color="text.primary">Request Headers: <pre style={{ margin: 0, fontStyle: 'italic' }}>{JSON.stringify(selectedLog.requestHeaders, null, 2)}</pre></Typography>
                                    <Typography variant="body1" color="text.primary">Request Body: <pre style={{ margin: 0, fontStyle: 'italic' }}>{JSON.stringify(selectedLog.requestBody, null, 2)}</pre></Typography>
                                    <Typography variant="body1" color="text.primary">Response Status: {selectedLog.responseStatus}</Typography>
                                    <Typography variant="body1" color="text.primary">Mocked: {selectedLog.isMocked ? 'Yes' : 'No'}</Typography>
                                    <Typography variant="body1" color="text.primary">Response Body: <pre style={{ margin: 0, fontStyle: 'italic' }}>{JSON.stringify(JSON.parse(selectedLog.responseBody), null, 2)}</pre></Typography>
                                    <Typography variant="body1" color="text.primary">Response Headers: <pre style={{ margin: 0, fontStyle: 'italic' }}>{JSON.stringify(selectedLog.responseHeaders, null, 2)}</pre></Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </>
            )}

            {isProjectLogsLoading ? (
                <Box
                    sx={{ width: '100%', marginTop: '40px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            ) : null}

            {!isLastPage ? (
                <Box
                    sx={{ marginBottom: 2 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Button variant="outlined" onClick={() => fetchProjectLogs()}>Load More</Button>
                </Box>
            ) : null}
        </Paper>
    );
}

export default ProjectViewLogsComponent;
