import React from 'react';
import ProjectListComponent from '../../components/projects/ProjectListComponent';
import { useNavigate, useParams } from 'react-router';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useProjects } from '../../providers/ProjectsProvider';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';

function ProjectListPage() {
  const navigate = useNavigate();
  const { teamId } = useParams();
  const { selectedTeam } = useProjects();

  const handleBackNavigation = async () => {
    navigate(-1);
  };

  const handleCreateNewProject = async () => {
    navigate(`/team/${teamId}/project/create`);
  };

  const handleViewCollections = async () => {
    navigate(`/team/${teamId}/collections`);
  };

  return (
    <SideDrawer title={`${selectedTeam.name} - Projects`}>
      <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
        <Grid item display="flex" alignItems="center">
          <Button
            variant="text"
            onClick={handleBackNavigation}
          >
            <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
          </Button>

          <NavBreadcrumbs
            currentPageTitle="Projects"
            history={[
              {
                title: "Teams",
                path: "/teams"
              }
            ]}
          />
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Button
            variant="outlined"
            onClick={handleCreateNewProject}
            sx={{
              background: 'white',
            }}
          >
            <AddCircleOutlineIcon sx={{ marginRight: '10px' }} /> Create new project
          </Button>

          <Button
            variant="outlined"
            onClick={handleViewCollections}
            sx={{
              background: 'white',
              marginLeft: '20px',
            }}
          >
            <ListAltIcon sx={{ marginRight: '10px' }} /> View Collections
          </Button>
        </Grid>
      </Grid>
      <ProjectListComponent teamId={teamId} />
    </SideDrawer>
  );
}

export default ProjectListPage;
