import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useApi } from '../../providers/ApiContext';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/common/Copyright';
import { Alert } from '@mui/material';
import { ReactComponent as Logo } from '../../components/common/asset/logo.svg'; 

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const { isAuthenticated, performLogin } = useApi();

    const handleLogin = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        performLogin(
            data.get('email'),
            data.get('password'),
            (user) => {
                navigate('/teams');
            },
            (error) => {
                if (error.response) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('Oops! Something went wrong. Please try again later.');
                }
            }
        );
    };

    if (isAuthenticated) {
        return <Navigate to="/teams" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box display="flex" alignItems="center">
                    <Logo height={80} width={80} fill='black' sx={{ display: { xs: 'none', md: 'flex' }, color: 'white' }} />

                    <Typography variant="h4" sx={{ marginLeft: 2, fontWeight: 'bold' }}>
                        {process.env.REACT_APP_WEBSITE_NAME}
                    </Typography>
                </Box>

                <Avatar sx={{ m: 1, mt: 8, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                {errorMessage && errorMessage.trim() !== '' && (
                    <Alert severity="error" sx={{ width: '100%', marginTop: '10px' }}>
                        {errorMessage}
                    </Alert>
                )}

                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <Link href="/registration" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
                <Copyright sx={{ mt: 8, mb: 15 }} />
            </Box>
        </Container>
    );
}

export default LoginPage;
