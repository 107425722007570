import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';

const ApiContext = createContext();

export function useApi() {
    return useContext(ApiContext);
}

export function ApiProvider({ children }) {
    const navigate = useNavigate();
    const storedTokens = localStorage.getItem('tokens');
    const storedUser = localStorage.getItem('user');
    const [tokens, setTokens] = useState(storedTokens ? JSON.parse(storedTokens) : null);
    const [user, setUser] = useState(storedUser ? JSON.parse(storedUser) : null);
    const [requestCount, setRequestCount] = useState(0);

    const isAuthenticated = !!tokens;

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    const performAuth = async (endpoint, requestData, onSuccess, onFailure) => {
        try {
            const response = await axiosInstance.post(endpoint, requestData);

            const { tokens, user } = response.data;

            localStorage.setItem('tokens', JSON.stringify(tokens));
            localStorage.setItem('user', JSON.stringify(user));
            setTokens(tokens);
            setUser(user);

            if (onSuccess) {
                onSuccess(user);
            }
        } catch (error) {
            // console.log('error:', error.message);
            if (onFailure) {
                onFailure(error);
            }
        }
    };

    const performLogin = async (email, password, onSuccess, onFailure) => {
        const requestData = {
            email,
            password,
        };
        performAuth('/v1/auth/login', requestData, onSuccess, onFailure);
    };

    const performRegistration = async (name, email, password, onSuccess, onFailure) => {
        const requestData = {
            name,
            email,
            password,
        };
        performAuth('/v1/auth/register', requestData, onSuccess, onFailure);
    };

    const performRefreshTokens = async () => {
        try {
            const response = await axiosInstance.post('/v1/auth/refresh-tokens', {
                refreshToken: JSON.parse(localStorage.getItem('tokens')).refresh.token,
            });

            localStorage.setItem('tokens', JSON.stringify(response.data));
            setTokens(response.data);

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    const refreshUserDetails = async (onSuccess, onFailure) => {
        try {
            const response = await axiosInstance.get(`/v1/users/${user.id}`);
            localStorage.setItem('user', JSON.stringify(response.data));
            setUser(response.data);

            if (onSuccess) {
                onSuccess(response.data);
            }
        } catch (error) {
            // console.log('error:', error.message);
            if (onFailure) {
                onFailure(error);
            }
        }
    };

    const performLogout = () => {
        localStorage.removeItem('tokens');
        localStorage.removeItem('user');
        setTokens(null);
        setUser(null);
    };

    axiosInstance.interceptors.request.use(
        (request) => {
            // console.log('Starting Request', JSON.stringify(request, null, 2));
            setRequestCount((currentCount) => currentCount + 1);

            // Read the latest tokens from localStorage instead of using the state
            const storedTokens = localStorage.getItem('tokens');
            const currentTokens = storedTokens ? JSON.parse(storedTokens) : null;

            // Add authorization header if tokens are present
            if (currentTokens && !request.headers['Authorization']) {
                request.headers['Authorization'] = `Bearer ${currentTokens.access.token}`;
            }
            return request;
        },
        (error) => {
            setRequestCount((currentCount) => Math.max(0, currentCount - 1));
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            // console.log('Response:', JSON.stringify(response, null, 2));
            setRequestCount((currentCount) => Math.max(0, currentCount - 1));
            return response;
        },
        async (error) => {
            const originalRequest = error.config;

            // Check if the error is due to unauthorized and if a token refresh is needed.
            // This logic does not apply to refresh-tokens API.
            if (error.response.status === 401 && !originalRequest._retry && !originalRequest.url.includes('refresh-tokens')) {
                originalRequest._retry = true;

                try {
                    const refreshedTokens = await performRefreshTokens();

                    // Retry the original request with the new tokens
                    originalRequest.headers['Authorization'] = `Bearer ${refreshedTokens.access.token}`;
                    return axiosInstance(originalRequest);
                } catch (e) {
                    // If token refresh fails, logout the user
                    performLogout();
                    navigate('/login');
                    setRequestCount((currentCount) => Math.max(0, currentCount - 1));
                    return Promise.reject(error);
                }
            } else if (error.response.status === 403) {
                navigate('/unauthorized');
            }

            setRequestCount((currentCount) => Math.max(0, currentCount - 1));
            return Promise.reject(error);
        }
    );

    return (
        <ApiContext.Provider value={{
            axiosInstance, requestCount,
            tokens, user, isAuthenticated,
            performLogin, performRegistration, performRefreshTokens, performLogout,
            refreshUserDetails
        }}>
            {children}
        </ApiContext.Provider>
    );
}
