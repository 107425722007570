import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useApi } from "../../providers/ApiContext";
import { JsonEditor } from 'jsoneditor-react18';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Box,
    Chip,
    Divider,
    Tooltip,
} from '@mui/material';
import httpResponsePossibilities from "../../config/httpResponsePossibilities";
import { toast } from 'react-toastify';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

function ProjectCreateUpdateEndpointComponent({ projectId, existingEndpoint, duplicateEndpoint }) {
    const navigate = useNavigate();
    const { axiosInstance } = useApi();
    const [formData, setFormData] = useState(existingEndpoint || duplicateEndpoint || {
        name: '',
        description: '',
        targetEndpointRequestType: 'GET',
        targetEndpointUrl: '',
        urlParametersCriteria: [],
        headersCriteria: [],
        mockResponseHttpStatus: 200,
        mockResponsePayload: '',
        mockResponseHeaders: [],
        hitsLimit: -1,
        hitsCount: 0,
        priority: 1,
        isEnabled: true,
    });
    const [mockResponsePayloadObject, setMockResponsePayloadObject] = useState(
        existingEndpoint && existingEndpoint.mockResponsePayload ?
            JSON.parse(existingEndpoint.mockResponsePayload) :
            duplicateEndpoint && duplicateEndpoint.mockResponsePayload ?
                JSON.parse(duplicateEndpoint.mockResponsePayload) :
                {}
    );

    const addUrlParameter = () => {
        setFormData({
            ...formData,
            urlParametersCriteria: [
                ...formData.urlParametersCriteria,
                {
                    description: '',
                    parameterKey: '',
                    expectedParameterValue: '',
                },
            ],
        });
    };

    const removeUrlParameter = (index) => {
        const updatedUrlParameters = [...formData.urlParametersCriteria];
        updatedUrlParameters.splice(index, 1);
        setFormData({
            ...formData,
            urlParametersCriteria: updatedUrlParameters,
        });
    };

    const addHeaderCriteria = () => {
        const newHeaderCriteria = {
            description: '',
            headerKey: '',
            expectedHeaderValue: '',
            isJWT: false,
            jwtCriteria: [],
        };
        setFormData((prevData) => ({
            ...prevData,
            headersCriteria: [...prevData.headersCriteria, newHeaderCriteria],
        }));
    };

    const handleHeaderChange = (e, field, index) => {
        const value =
            e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setFormData((prevData) => {
            const updatedHeadersCriteria = [...prevData.headersCriteria];
            updatedHeadersCriteria[index][field] = value;

            // Check if isJWT needs to be updated and array cleared
            if (!updatedHeadersCriteria[index].isJWT) {
                updatedHeadersCriteria[index].jwtCriteria = [];
            }

            return { ...prevData, headersCriteria: updatedHeadersCriteria };
        });
    };

    const removeHeaderCriteria = (index) => {
        const updatedHeadersCriteria = [...formData.headersCriteria];
        updatedHeadersCriteria.splice(index, 1);
        setFormData((prevData) => ({
            ...prevData,
            headersCriteria: updatedHeadersCriteria,
        }));
    };

    const handleJWTChange = (e, field, headerIndex, jwtIndex) => {
        const updatedHeaderCriteria = [...formData.headersCriteria];
        updatedHeaderCriteria[headerIndex].jwtCriteria[jwtIndex][field] = e.target.value;

        setFormData({
            ...formData,
            headersCriteria: updatedHeaderCriteria,
        });
    };

    const addJWTCriteria = (headerIndex) => {
        const updatedHeaderCriteria = [...formData.headersCriteria];
        updatedHeaderCriteria[headerIndex].jwtCriteria.push({
            fieldKey: '',
            expectedFieldValue: '',
        });

        setFormData({
            ...formData,
            headersCriteria: updatedHeaderCriteria,
        });
    };

    const removeJWTCriteria = (headerIndex, jwtIndex) => {
        const updatedHeaderCriteria = [...formData.headersCriteria];
        updatedHeaderCriteria[headerIndex].jwtCriteria.splice(jwtIndex, 1);

        // Check if isJWT needs to be updated
        if (updatedHeaderCriteria[headerIndex].jwtCriteria.length == 0) {
            updatedHeaderCriteria[headerIndex].isJWT = false;
        }

        setFormData({
            ...formData,
            headersCriteria: updatedHeaderCriteria,
        });
    };

    const addMockResponseHeader = () => {
        setFormData({
            ...formData,
            mockResponseHeaders: [
                ...formData.mockResponseHeaders,
                {
                    key: '',
                    value: '',
                },
            ],
        });
    };

    const removeMockResponseHeader = (index) => {
        const updatedMockResponseHeaders = [...formData.mockResponseHeaders];
        updatedMockResponseHeaders.splice(index, 1);
        setFormData({
            ...formData,
            mockResponseHeaders: updatedMockResponseHeaders,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Making sure there's no arrays with empty values
            var validFormData = formData;
            validFormData.urlParametersCriteria = validFormData.urlParametersCriteria.filter((item) => item.parameterKey !== '' && item.expectedParameterValue !== '' && item.parameterKey !== null && item.expectedParameterValue !== null);
            validFormData.headersCriteria = validFormData.headersCriteria.filter((item) => item.headerKey !== '' && item.headerKey !== null);
            validFormData.mockResponseHeaders = validFormData.mockResponseHeaders.filter((item) => item.key !== '' && item.value !== '' && item.key !== null && item.value !== null);

            if (existingEndpoint) {
                const updateEndpointResponse = await axiosInstance.patch(`/v1/admin/project/${projectId}/endpoint/${existingEndpoint._id}`, validFormData);
            } else {
                const createEndpointResponse = await axiosInstance.post(`/v1/admin/project/${projectId}/endpoint`, validFormData);
            }

            // Successful response, navigate back
            navigate(-1);
        } catch (error) {
            toast.error(`Sorry, ${existingEndpoint ? 'updating' : 'creating'} this mock endpoint was unsuccessful. Please make sure all required fields have been set before trying again.`);
        }
    }

    return (
        <Paper elevation={1} style={{ padding: 30 }}>
            <Divider><Chip variant="outlined" label={`${existingEndpoint ? 'Update' : 'Create'} Endpoint Details`} sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>
            <form onSubmit={handleSubmit}>
                <Box marginTop={4}>
                    <TextField
                        label="Name"
                        type="text"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        required
                        fullWidth
                    />
                </Box>
                <Box marginTop={4}>
                    <TextField
                        label="Description"
                        multiline
                        rows={4}
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        fullWidth
                    />
                </Box>
                <Box marginTop={4} display="flex" alignItems="center">
                    <FormControl sx={{ flex: '1', marginRight: 2 }}>
                        <InputLabel sx={{ backgroundColor: 'white', paddingRight: 1, paddingLeft: 1 }}>Request Type</InputLabel>
                        <Select
                            value={formData.targetEndpointRequestType}
                            onChange={(e) =>
                                setFormData({ ...formData, targetEndpointRequestType: e.target.value })
                            }
                            required
                        >
                            {['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'COPY', 'HEAD', 'OPTIONS', 'LINK', 'UNLINK', 'PURGE', 'LOCK', 'UNLOCK', 'PROPFIND', 'VIEW'].map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Endpoint URL"
                        type="text"
                        value={formData.targetEndpointUrl}
                        onChange={(e) => setFormData({ ...formData, targetEndpointUrl: e.target.value })}
                        required
                        sx={{ flex: '5' }}
                    />
                </Box>


                {/* Additional Fields */}
                <Box marginTop={4}>
                    <Divider><Chip variant="outlined" label="Advanced Settings" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>
                    <Box marginTop={4}>
                        <Tooltip
                            title="Hits Limit: This value represents the maximum number of times the mocked response will be returned. Once this limit is reached, the mock endpoint will be considered as disabled, and subsequent requests will result in a simple proxy response instead. If the value is set to -1, it means there is no limit, and the mock response will be returned an unlimited number of times."
                            sx={{ marginRight: 2 }}
                        >
                            <TextField
                                label="Hits Limit"
                                type="number"
                                value={formData.hitsLimit}
                                onChange={(e) =>
                                    setFormData({ ...formData, hitsLimit: parseInt(e.target.value) })
                                }
                                required
                            />

                        </Tooltip>
                        <Tooltip
                            title="Hit Count: This value represents how many times a request to this mock endpoint has been made, and the mocked response was sent back in response. It tracks the number of successful responses provided by the mock endpoint."
                            sx={{ marginRight: 2 }}
                        >
                            <TextField
                                label="Hits Count"
                                type="number"
                                value={formData.hitsCount}
                                onChange={(e) =>
                                    setFormData({ ...formData, hitsCount: parseInt(e.target.value) })
                                }
                                required
                            />
                        </Tooltip>
                        <Tooltip
                            title="Priority determines the order in which this mock endpoint is matched against incoming requests. Lower values indicate higher priority. In case of multiple matching endpoints, the one with the lowest priority value will be selected first."
                            sx={{ marginRight: 1 }}
                        >
                            <TextField
                                label="Priority"
                                type="number"
                                value={formData.priority}
                                onChange={(e) =>
                                    setFormData({ ...formData, priority: parseInt(e.target.value) })
                                }
                                required
                            />

                        </Tooltip>
                    </Box>
                </Box>

                {/* URL Parameters Criteria */}
                <Box marginTop={4}>
                    <Divider><Chip variant="outlined" label="URL Parameters Criteria" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>

                    <Box display="flex">
                        <Button
                            variant="outlined"
                            onClick={addUrlParameter}
                            sx={{ marginTop: 4, marginLeft: 'auto', marginRight: 'auto' }}
                        >
                            Add URL Parameter
                        </Button>
                    </Box>

                    {formData.urlParametersCriteria.map((urlParam, index) => (
                        <Box key={index} marginTop={4} display="flex" alignItems="center">
                            <TextField
                                label="Description"
                                type="text"
                                value={urlParam.description}
                                onChange={(e) => {
                                    const updatedUrlParameters = [...formData.urlParametersCriteria];
                                    updatedUrlParameters[index].description = e.target.value;
                                    setFormData({
                                        ...formData,
                                        urlParametersCriteria: updatedUrlParameters,
                                    });
                                }}
                                sx={{ flex: '1', marginRight: 2 }}
                            />
                            <TextField
                                label="Parameter Key"
                                type="text"
                                value={urlParam.parameterKey}
                                onChange={(e) => {
                                    const updatedUrlParameters = [...formData.urlParametersCriteria];
                                    updatedUrlParameters[index].parameterKey = e.target.value;
                                    setFormData({
                                        ...formData,
                                        urlParametersCriteria: updatedUrlParameters,
                                    });
                                }}
                                sx={{ flex: '1', marginRight: 2 }}
                            />
                            <TextField
                                label="Expected Parameter Value"
                                type="text"
                                value={urlParam.expectedParameterValue}
                                onChange={(e) => {
                                    const updatedUrlParameters = [...formData.urlParametersCriteria];
                                    updatedUrlParameters[index].expectedParameterValue = e.target.value;
                                    setFormData({
                                        ...formData,
                                        urlParametersCriteria: updatedUrlParameters,
                                    });
                                }}
                                sx={{ flex: '1', marginRight: 1 }}
                            />
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={() => removeUrlParameter(index)}
                                sx={{ alignSelf: 'center' }}
                            >
                                <RemoveCircleOutlineIcon />
                            </Button>
                        </Box>
                    ))}
                </Box>

                {/* Headers Criteria */}
                <Box marginTop={4}>
                    <Divider><Chip variant="outlined" label="Headers Criteria" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>

                    <Box display="flex">
                        <Button
                            variant="outlined"
                            onClick={addHeaderCriteria}
                            sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 4 }}
                        >
                            Add Header Criteria
                        </Button>
                    </Box>

                    {formData.headersCriteria.map((headerCriteria, index) => (
                        <Box marginTop={4} key={index}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label="Description"
                                    type="text"
                                    value={headerCriteria.description}
                                    onChange={(e) => handleHeaderChange(e, 'description', index)}
                                    sx={{ flex: '1', marginRight: 2 }}
                                />
                                <TextField
                                    label="Header Key"
                                    type="text"
                                    value={headerCriteria.headerKey}
                                    onChange={(e) => handleHeaderChange(e, 'headerKey', index)}
                                    sx={{ flex: '1', marginRight: 2 }}
                                />

                                {/* Conditionally render expectedHeaderValue based on "Is JWT" */}
                                {!headerCriteria.isJWT && (
                                    <TextField
                                        label="Expected Header Value"
                                        type="text"
                                        value={headerCriteria.expectedHeaderValue}
                                        onChange={(e) =>
                                            handleHeaderChange(e, 'expectedHeaderValue', index)
                                        }
                                        sx={{ flex: '1', marginRight: 1 }}
                                    />
                                )}

                                {/* Render "Is JWT" checkbox for each headerCriteria item */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={headerCriteria.isJWT}
                                            onChange={(e) => {
                                                if (!headerCriteria.isJWT && headerCriteria.jwtCriteria.length == 0) {
                                                    addJWTCriteria(index);
                                                }
                                                handleHeaderChange(e, 'isJWT', index);
                                            }}
                                        />
                                    }
                                    label="Is JWT"
                                    sx={{ flex: '0.4', marginRight: 1 }}
                                />

                                {/* Render a button to add new jwtCriteria items */}
                                {headerCriteria.isJWT && (
                                    <Button
                                        variant="outlined"
                                        onClick={() => addJWTCriteria(index)}
                                        sx={{ flex: '0.4', marginRight: 1 }}
                                    >
                                        Add JWT Criteria
                                    </Button>
                                )}

                                {/* Render a button to remove this headerCriteria item */}
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={() => removeHeaderCriteria(index)}
                                >
                                    <RemoveCircleOutlineIcon />
                                </Button>
                            </Box>
                            <Box>
                                {/* Conditionally render jwtCriteria based on "Is JWT" */}
                                {headerCriteria.isJWT && (
                                    <Box sx={{ marginLeft: 8 }}>
                                        {headerCriteria.jwtCriteria.map((jwtCriteriaItem, jwtIndex) => (
                                            <Box marginTop={4} key={jwtIndex} display="flex" alignItems="center">
                                                <SubdirectoryArrowRightIcon sx={{ color: 'grey', marginRight: 2 }} />
                                                <TextField
                                                    label="JWT Field Key"
                                                    type="text"
                                                    value={jwtCriteriaItem.fieldKey}
                                                    onChange={(e) =>
                                                        handleJWTChange(e, 'fieldKey', index, jwtIndex)
                                                    }
                                                    sx={{ flex: '1', marginRight: 2 }}
                                                />
                                                <TextField
                                                    label="Expected JWT Field Value"
                                                    type="text"
                                                    value={jwtCriteriaItem.expectedFieldValue}
                                                    onChange={(e) =>
                                                        handleJWTChange(e, 'expectedFieldValue', index, jwtIndex)
                                                    }
                                                    sx={{ flex: '1', marginRight: 1 }}
                                                />
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => removeJWTCriteria(index, jwtIndex)}
                                                >
                                                    <RemoveCircleOutlineIcon />
                                                </Button>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>


                {/* Mock Response */}
                <Box marginTop={4}>
                    <Divider><Chip variant="outlined" label="Mocked Response" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>
                    <Box marginTop={4} display="flex">
                        <FormControl sx={{ flex: '1', marginRight: 2 }}>
                            <InputLabel sx={{ backgroundColor: 'white', paddingRight: 1, paddingLeft: 1 }}>Response HTTP Status</InputLabel>
                            <Select
                                value={formData.mockResponseHttpStatus}
                                onChange={(e) =>
                                    setFormData({ ...formData, mockResponseHttpStatus: parseInt(e.target.value) })
                                }
                                required
                            >
                                {httpResponsePossibilities.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{ flex: '4' }}>
                            <JsonEditor
                                value={mockResponsePayloadObject}
                                mode='code'
                                navigationBar={true}
                                allowedModes={['tree', 'view', 'form', 'code', 'text']}
                                onChange={(data) => {
                                    setFormData({ ...formData, mockResponsePayload: JSON.stringify(data) });
                                    setMockResponsePayloadObject(data);
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Mock Response Headers */}
                <Box marginTop={4}>
                    <Divider><Chip variant="outlined" label="Response Headers" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>

                    <Box display="flex">
                        <Button
                            variant="outlined"
                            onClick={addMockResponseHeader}
                            sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 4 }}
                        >
                            Add Mock Response Header
                        </Button>
                    </Box>

                    {formData.mockResponseHeaders.map((header, index) => (
                        <Box marginTop={4} key={index} display="flex" alignItems="center">
                            <TextField
                                label="Key"
                                type="text"
                                value={header.key}
                                onChange={(e) => {
                                    const updatedHeaders = [...formData.mockResponseHeaders];
                                    updatedHeaders[index].key = e.target.value;
                                    setFormData({
                                        ...formData,
                                        mockResponseHeaders: updatedHeaders,
                                    });
                                }}
                                sx={{ flex: 1, marginRight: 2 }}
                            />
                            <TextField
                                label="Value"
                                type="text"
                                value={header.value}
                                onChange={(e) => {
                                    const updatedHeaders = [...formData.mockResponseHeaders];
                                    updatedHeaders[index].value = e.target.value;
                                    setFormData({
                                        ...formData,
                                        mockResponseHeaders: updatedHeaders,
                                    });
                                }}
                                sx={{ flex: 1, marginRight: 1 }}
                            />
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={() => removeMockResponseHeader(index)}
                            >
                                <RemoveCircleOutlineIcon />
                            </Button>
                        </Box>
                    ))}
                </Box>

                <Divider sx={{ marginTop: 4 }} />

                <Box display="flex">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ marginRight: 'auto', marginLeft: 'auto', marginTop: 4, marginBottom: 2 }}
                    >
                        {existingEndpoint ? 'Update' : 'Create'} Endpoint
                    </Button>
                </Box>
            </form>
        </Paper>
    );
}

export default ProjectCreateUpdateEndpointComponent;
