import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SideDrawer from '../../components/common/SideDrawer';
import { Box, Button, CircularProgress, Grid, Switch, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useProjects } from '../../providers/ProjectsProvider';
import { toast } from 'react-toastify';
import { useApi } from '../../providers/ApiContext';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';
import ProjectViewLogsComponent from '../../components/logs/ProjectViewLogsComponent';

function ProjectViewLogsPage() {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const { selectedTeam, getProjectById, updateProjectRecordMode } = useProjects();
    const { axiosInstance } = useApi();
    const project = getProjectById(projectId);
    const [isRecordModeToggleLoading, setIsRecordModeToggleLoading] = useState(false);

    const handleBackNavigation = async () => {
        navigate(-1);
    };

    const handleRecordModeToggle = async (event) => {
        try {
            setIsRecordModeToggleLoading(true);
            const response = await axiosInstance.post(`/v1/admin/project/${projectId}/log/request/enable`, { isRecordModeEnabled: event.target.checked });
            project.isRecordModeEnabled = event.target.checked;
            updateProjectRecordMode(projectId, event.target.checked);
            toast.success(`Successfully ${event.target.checked ? 'enabled' : 'disabled'} Record Mode`);
            setIsRecordModeToggleLoading(false);
        } catch (error) {
            toast.error('Toggle Record Mode failed, please try again later.');
            setIsRecordModeToggleLoading(false);
        }
    };

    return (
        <SideDrawer title='View Project Logs'>
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>

                <NavBreadcrumbs
                    currentPageTitle="View Logs"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        },
                        {
                            title: "Projects",
                            path: `/team/${selectedTeam._id}/projects`
                        },
                        {
                            title: "Endpoints",
                            path: `/project/${projectId}/endpoints`
                        }
                    ]}
                />

                <Grid item sx={{ marginLeft: 'auto' }}>
                    <Box display='flex' alignItems='center'>
                        <Typography variant="body">Record Mode:</Typography>
                        <Box sx={{ width: 60, height: 40 }} display='flex' alignItems='center'>
                            {isRecordModeToggleLoading ? (
                                <CircularProgress size={20} thickness={5} sx={{ marginRight: 'auto', marginLeft: 'auto' }} />
                            ) : (
                                <Switch
                                    checked={project.isRecordModeEnabled}
                                    onChange={handleRecordModeToggle}
                                    inputProps={{ 'aria-label': 'Record mode switch' }}
                                />
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <ProjectViewLogsComponent projectId={projectId} />
        </SideDrawer>
    );
}

export default ProjectViewLogsPage;