import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectCreateUpdateEndpointComponent from '../../components/endpoints/ProjectCreateUpdateEndpointComponent';
import { useProjects } from '../../providers/ProjectsProvider';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';

function ProjectUpdateExistingEndpointPage() {
    const navigate = useNavigate();
    const { projectId, endpointId } = useParams();
    const { selectedTeam, selectedEndpoint } = useProjects();

    const transformInitialEndpointData = (initialEndpointData) => {
        // Make sure isJWT is set before rendering the component, since being a value used only here
        if (initialEndpointData && initialEndpointData.headersCriteria && initialEndpointData.headersCriteria.length > 0) {
            const updatedEndpointData = initialEndpointData;
            updatedEndpointData.headersCriteria.forEach((element, index) => {
                updatedEndpointData.headersCriteria[index].isJWT = element.jwtCriteria && element.jwtCriteria.length > 0;
            })
            return updatedEndpointData;
        }
        return initialEndpointData;
    };

    useEffect(() => {
        if (!selectedEndpoint || selectedEndpoint._id !== endpointId) {
            navigate('/teams');
        }
    }, []);
    
    const handleBackNavigation = async () => {
        navigate(-1);
    };

    return (
        <SideDrawer title='Update Mock Endpoint'>
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>

                <NavBreadcrumbs
                    currentPageTitle="Update Endpoint"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        },
                        {
                            title: "Projects",
                            path: `/team/${selectedTeam._id}/projects`
                        },
                        {
                            title: "Endpoints",
                            path: `/project/${projectId}/endpoints`
                        }
                    ]}
                />
            </Grid>
            <ProjectCreateUpdateEndpointComponent projectId={projectId} existingEndpoint={transformInitialEndpointData(selectedEndpoint)} />
        </SideDrawer>
    );
}

export default ProjectUpdateExistingEndpointPage;