import React from 'react';
import ProjectCreateUpdateComponent from '../../components/projects/ProjectCreateUpdateComponent';
import { useNavigate, useParams } from 'react-router';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';

function ProjectCreatePage() {
    const navigate = useNavigate();
    const { teamId } = useParams();

    const handleBackNavigation = async () => {
        navigate(-1);
    };

    return (
        <SideDrawer title='Create New Project'>
            <Grid container display="flex" alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>

                <NavBreadcrumbs
                    currentPageTitle="Create Project"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        },
                        {
                            title: "Projects",
                            path: `/team/${teamId}/projects`
                        }
                    ]}
                />
            </Grid>
            <ProjectCreateUpdateComponent teamId={teamId} />
        </SideDrawer>
    );
}

export default ProjectCreatePage;
