import React, { useEffect, useRef, useState } from 'react';
import { useApi } from '../../providers/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../../providers/ProjectsProvider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Box, Button, CircularProgress, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

function UserListComponent() {
    const navigate = useNavigate();
    const [usersResponse, setUsersResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [deleteDialogUserId, setDeleteDialogUserId] = useState(null);
    const [updateDialogUserId, setUpdateDialogUserId] = useState(null);
    const { axiosInstance } = useApi();

    const [updatedName, setUpdatedName] = useState(null);
    const [updatedRole, setUpdatedRole] = useState(null);

    const defaultRowsPerPage = 10;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

    // Fetch Users
    const fetchUsers = async (pageToFetch, limit) => {
        try {
            setLoading(true);
            setLoaded(false);
            const response = await axiosInstance.get(`/v1/users?page=${pageToFetch + 1}&limit=${limit}`);
            setUsersResponse(response.data);
            setLoading(false);
            setLoaded(true);
        } catch (error) {
            toast.error('Sorry, an error occurred. We were not able to retrieve the list of users. Please try again later.');
            setLoading(false);
            setLoaded(true);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchUsers(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        fetchUsers(0, event.target.value);
    };

    const confirmDelete = async (userId) => {
        try {
            const response = await axiosInstance.delete(`/v1/users/${userId}`);

            setDeleteDialogUserId(null);
            toast.success('Successfully deleted');

            // Reset pagination and fetch users again
            setPage(0);
            fetchUsers(0, rowsPerPage);
        } catch (error) {
            setDeleteDialogUserId(null);
            toast.error('Deletion failed');
        }
    };

    const handleUpdateUserNameChange = (event) => {
        setUpdatedName(event.target.value);
    };

    const handleUpdateUserRoleChange = (event) => {
        setUpdatedRole(event.target.value);
    };

    const confirmUpdateUser = async (userId) => {
        try {
            const response = await axiosInstance.patch(`/v1/users/${userId}`, {
                name: updatedName,
                role: updatedRole
            });

            setUpdateDialogUserId(null);
            setUpdatedName(null);
            setUpdatedRole(null);
            toast.success('Successfully updated');

            // Reset pagination and fetch users again
            setPage(0);
            fetchUsers(0, rowsPerPage);
        } catch (error) {
            setUpdateDialogUserId(null);
            setUpdatedName(null);
            setUpdatedRole(null);
            toast.error('Update failed');
        }
    };

    useEffect(() => {
        if (!loading && !loaded) {
            fetchUsers(page, rowsPerPage);
        }
    }, [loading, loaded]);

    return (
        <div>
            {(loaded && usersResponse) &&
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        key='name'
                                        align='left'
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        key='email'
                                        align='left'
                                    >
                                        Email
                                    </TableCell>
                                    <TableCell
                                        key='role'
                                        align='left'
                                    >
                                        Role
                                    </TableCell>
                                    <TableCell
                                        key='actions'
                                        align='center'
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersResponse.results.map((user) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                                        <TableCell align='left'>
                                            {user.name}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {user.email}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {user.role}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Tooltip
                                                title="Edit user"
                                            >
                                                <Button
                                                    aria-label="edit user"
                                                    variant="text"
                                                    sx={{ marginRight: '10px' }}
                                                    onClick={() => { 
                                                        setUpdatedName(user.name);
                                                        setUpdatedRole(user.role);
                                                        setUpdateDialogUserId(user.id) ;
                                                    }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Delete user"
                                            >
                                                <Button
                                                    aria-label="delete user"
                                                    variant="text"
                                                    onClick={() => { setDeleteDialogUserId(user.id) }}
                                                >
                                                    <DeleteIcon sx={{ marginRight: '10px' }} />
                                                </Button>
                                            </Tooltip>
                                        </TableCell>

                                        {/* Delete Confirmation Dialog */}
                                        <Dialog
                                            open={deleteDialogUserId == user.id}
                                            onClose={() => setDeleteDialogUserId(null)}
                                        >
                                            <DialogTitle>Confirm Deletion</DialogTitle>
                                            <DialogContent>
                                                Are you sure you want to delete this user?
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setDeleteDialogUserId(null)} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => confirmDelete(user.id)}
                                                    color="secondary"
                                                    variant="contained"
                                                >
                                                    Delete
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        {/* Update User Details Dialog */}
                                        <Dialog 
                                            open={updateDialogUserId == user.id}
                                            onClose={() => setUpdateDialogUserId(null)}
                                        >
                                            <DialogTitle>Update User</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    label="Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    sx={{ marginTop: 2 }}
                                                    value={updatedName}
                                                    onChange={handleUpdateUserNameChange}
                                                />
                                                <FormControl fullWidth variant="outlined" sx={{ marginTop: 4 }}>
                                                    <InputLabel>Role</InputLabel>
                                                    <Select
                                                        label="Role"
                                                        value={updatedRole}
                                                        onChange={handleUpdateUserRoleChange}
                                                    >
                                                        <MenuItem value="user">User - No access to portal</MenuItem>
                                                        <MenuItem value="admin">Admin - Access to portal</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setUpdateDialogUserId(null)} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button onClick={() => confirmUpdateUser(user.id)} color="primary">
                                                    Update
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={usersResponse.totalResults}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            }

            {(loading) &&
                <Box
                    sx={{ width: '100%', marginTop: '40px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            }
        </div>
    );
}

export default UserListComponent;