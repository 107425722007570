import React from 'react';
import { useNavigate, useParams } from 'react-router';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';
import UserListComponent from '../../components/users/UserListComponent';

function UserListPage() {
    const navigate = useNavigate();

    const handleBackNavigation = async () => {
        navigate(-1);
    };

    return (
        <SideDrawer title={`User Management`}>
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item display="flex" alignItems="center">
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>

                    <NavBreadcrumbs
                        currentPageTitle="Users"
                        history={[
                            {
                                title: "Teams",
                                path: "/teams"
                            }
                        ]}
                    />
                </Grid>
            </Grid>

            <UserListComponent />
        </SideDrawer>
    );
}

export default UserListPage;
