import React from 'react';
import { useNavigate } from 'react-router';
import SideDrawer from '../../components/common/SideDrawer';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TeamListComponent from '../../components/teams/TeamListComponent';

function TeamListPage() {
  const navigate = useNavigate();

  const handleCreateNewTeam = async () => {
    navigate('/team/create');
  };

  return (
    <SideDrawer title='Teams'>
      <Button
        variant="outlined"
        onClick={handleCreateNewTeam}
        sx={{
          display: 'flex',
          marginLeft: 'auto',
          marginBottom: '30px',
          background: 'white',
        }}
      >
        <AddCircleOutlineIcon sx={{ marginRight: '10px' }} /> Create new team
      </Button>
      <TeamListComponent />
    </SideDrawer>
  );
}

export default TeamListPage;
