import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { AccountCircleOutlined } from '@mui/icons-material';
import { useApi } from '../../providers/ApiContext';
import Copyright from '../../components/common/Copyright';
import { CircularProgress, Link, SvgIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { ReactComponent as Logo } from './asset/logo.svg';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function SideDrawer({ title, children }) {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openProfileSettings, setOpenProfileSettings] = React.useState(false);
  const anchorRefProfileSettings = React.useRef(null);
  const { performLogout } = useApi();
  const navigate = useNavigate();
  const { requestCount } = useApi();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleToggleProfileSettings = () => {
    setOpenProfileSettings((prevOpen) => !prevOpen);
  };

  const handleCloseProfileSettings = (event) => {
    if (anchorRefProfileSettings.current && anchorRefProfileSettings.current.contains(event.target)) {
      return;
    }

    setOpenProfileSettings(false);
  };

  function handleProfileSettingsListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProfileSettings(false);
    } else if (event.key === 'Escape') {
      setOpenProfileSettings(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenProfileSettings = React.useRef(openProfileSettings);
  React.useEffect(() => {
    if (prevOpenProfileSettings.current === true && openProfileSettings === false) {
      anchorRefProfileSettings.current.focus();
    }

    prevOpenProfileSettings.current = openProfileSettings;
  }, [openProfileSettings]);

  const handleLogout = () => {
    performLogout();
  };

  const handleManageUsers = () => {
    navigate('/users');
  };

  // Note: The side drawer was not necessary for this MVP. Can be an improvement for the future. 
  // In the meantime, the related code has been commented out.
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={openDrawer}>
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openDrawer && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton> */}

          <Link href="/teams" underline="none" color="white">
            <Box display="flex" alignItems="center" sx={{ marginLeft: 2 }}>
              <Logo height={40} width={40} fill='white' sx={{ display: { xs: 'none', md: 'flex' }, color: 'white' }} />

              <Typography variant="h6" noWrap component="div" sx={{ marginLeft: 2 }}>
                {process.env.REACT_APP_WEBSITE_NAME}
              </Typography>
            </Box>
          </Link>

          <Divider orientation="vertical" flexItem color="white" sx={{ marginTop: 2, marginBottom: 2, marginLeft: 2 }} />

          <Typography variant="h6" noWrap component="div" sx={{ marginLeft: 2 }}>
            {title}
          </Typography>

          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            sx={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            {requestCount > 0 ? (
              <CircularProgress
                size={20}
                thickness={5}
                color='inherit'
                sx={{
                  marginRight: 2,
                }}
              />
            ) : null}

            <IconButton
              ref={anchorRefProfileSettings}
              color="inherit"
              onClick={handleToggleProfileSettings}
              edge="end"
            >
              <AccountCircleOutlined />
            </IconButton>
          </Box>

          <Popper
            open={openProfileSettings}
            anchorEl={anchorRefProfileSettings.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseProfileSettings}>
                    <MenuList
                      autoFocusItem={openProfileSettings}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleProfileSettingsListKeyDown}
                    >
                      <MenuItem
                        onClick={(event) => {
                          handleCloseProfileSettings(event);
                          handleManageUsers();
                        }}
                      >
                        Manage Users
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleCloseProfileSettings(event);
                          handleLogout();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
      {/* <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: openDrawer ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: openDrawer ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: 'rgb(238, 242, 246)',
          minHeight: '100vh',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DrawerHeader />
        {children}
        <Box
          sx={{
            marginTop: 'auto',
            paddingTop: '40px',
          }}
        >
          <Copyright />
        </Box>
      </Box>

      <ToastContainer />
    </Box>
  );
}

export default SideDrawer;