import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

function NotFoundPage() {
    const navigate = useNavigate();

    const handleMainRedirect = async () => {
        navigate('/teams');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} textAlign="center">
                        <img
                            src="/logo-404.png"
                            alt=""
                            height={300}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h1">
                            404
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h6">
                            The page you're looking for doesn't exist.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Button variant="contained" onClick={handleMainRedirect}>
                            Back Home
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default NotFoundPage;
