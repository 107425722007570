import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectEndpointListComponent from '../../components/endpoints/ProjectEndpointListComponent';
import { Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, List, ListItem, ListItemText, Radio, RadioGroup, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SideDrawer from '../../components/common/SideDrawer';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';
import { useProjects } from '../../providers/ProjectsProvider';
import { toast } from 'react-toastify';
import { useApi } from '../../providers/ApiContext';
import { PlaylistAdd } from '@mui/icons-material';

function ProjectEndpointListPage() {
    const navigate = useNavigate();
    const { axiosInstance } = useApi();
    const { projectId } = useParams();
    const { selectedTeam, setSelectedDuplicateEndpoint } = useProjects();
    const [teamsWithCollections, setTeamsWithCollections] = useState([]);
    const [isCollectionsLoading, setIsCollectionsLoading] = useState(false);
    const [isCollectionsLoaded, setIsCollectionsLoaded] = useState(false);
    const [endpointForCollection, setEndpointForCollection] = useState(null);
    const [selectedTeamAndCollectionId, setSelectedTeamAndCollectionId] = useState(null);
    const [isAddToCollectionInProgress, setIsAddToCollectionInProgress] = useState(false);
    const [isImportCollection, setIsImportCollection] = useState(false);
    const [reloadMockEndpoints, setReloadMockEndpoints] = useState(0);

    const handleBackNavigation = async () => {
        navigate(-1);
    };

    const handleCreateNewEndpoint = async () => {
        setSelectedDuplicateEndpoint(null);
        navigate(`/project/${projectId}/endpoints/create`);
    };

    const handleImportCollection = async () => {
        if (!isCollectionsLoaded && !isCollectionsLoading) {
            fetchAllCollections(null, true);
        } else {
            setIsImportCollection(true);
        }
    };

    const handleOnImportCollectionDialogDismissed = async () => {
        setIsImportCollection(false);
        setSelectedTeamAndCollectionId(null);
    };

    const confirmImportCollectionToProject = async () => {
        try {
            const [teamId, collectionId] = selectedTeamAndCollectionId.split(':');
            await axiosInstance.post(`/v1/admin/project/${projectId}/import`, {
                teamId, 
                collectionId,
            });
            toast.success(`Collection successfully imported to this project.`);
            handleOnImportCollectionDialogDismissed();
            setReloadMockEndpoints(prev => prev + 1);
        } catch (error) {
            toast.error('Sorry, an error occurred. We were not able to import the collection to this project. Please try again later.');
            handleOnImportCollectionDialogDismissed();
        }
    };

    const handleOnAddEndpointToCollectionClicked = async (endpoint) => {
        if (!isCollectionsLoaded && !isCollectionsLoading) {
            fetchAllCollections(endpoint, false);
        } else {
            setEndpointForCollection(endpoint);
        }
    };

    const handleOnAddEndpointToCollectionDialogDismissed = async () => {
        setEndpointForCollection(null);
        setSelectedTeamAndCollectionId(null);
        setIsAddToCollectionInProgress(false);
    };

    const confirmAddEndpointToCollection = async (endpoint) => {
        setIsAddToCollectionInProgress(true);
        try {
            const [teamId, collectionId] = selectedTeamAndCollectionId.split(':');
            const { _id, createdAt, updatedAt, creator, ...cleanedEndpoint } = endpoint;
            await axiosInstance.post(`/v1/admin/team/${teamId}/collection/${collectionId}/endpoint`, cleanedEndpoint);
            updateCollectionEndpointCountAfterAddingEndpoint(teamId, collectionId);
            toast.success(`Endpoint successfully added to the selected collection.`);
            handleOnAddEndpointToCollectionDialogDismissed();
        } catch (error) {
            toast.error('Sorry, an error occurred. We were not able to add the endpoint to this collection. Please try again later.');
            handleOnAddEndpointToCollectionDialogDismissed();
        }
    };

    const updateCollectionEndpointCountAfterAddingEndpoint = (teamId, collectionId) => {
        const updatedTeams = teamsWithCollections.map((team) => {
            if (team._id === teamId) {
                const updatedCollections = team.collections.map((collection) => {
                    if (collection._id === collectionId) {
                        return { ...collection, mockEndpointsCount: collection.mockEndpointsCount + 1 };
                    }
                    return collection;
                });
                return { ...team, collections: updatedCollections };
            }
            return team;
        });
    
        setTeamsWithCollections(updatedTeams);
    };

    const handleViewLogs = async () => {
        navigate(`/project/${projectId}/logs`);
    };

    const fetchAllCollections = async (endpointToAdd, isCollectionToBeImported) => {
        setIsCollectionsLoading();
        try {
            const response = await axiosInstance.get(`/v1/admin/collections`);
            setTeamsWithCollections(response.data);
            setIsCollectionsLoading(false);
            setIsCollectionsLoaded(true);
            setEndpointForCollection(endpointToAdd);
            setIsImportCollection(isCollectionToBeImported)
        } catch (error) {
            toast.error('Sorry, an error occurred. We were not able to retrieve the list of collections. Please try again later.');
            setIsCollectionsLoading(false);
            setIsCollectionsLoaded(true);
        }
    };

    return (
        <SideDrawer title="Project's Mock Endpoints">
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>

                <NavBreadcrumbs
                    currentPageTitle="Endpoints"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        },
                        {
                            title: "Projects",
                            path: `/team/${selectedTeam._id}/projects`
                        }
                    ]}
                />

                <Grid item sx={{ marginLeft: 'auto' }}>
                    <Button
                        variant="outlined"
                        onClick={handleCreateNewEndpoint}
                        sx={{
                            background: 'white',
                        }}
                    >
                        <AddCircleOutlineIcon sx={{ marginRight: '10px' }} /> Create new endpoint
                    </Button>

                    <Button
                        variant="outlined"
                        onClick={handleImportCollection}
                        sx={{
                            background: 'white',
                            marginLeft: '20px'
                        }}
                    >
                        <PlaylistAdd sx={{ marginRight: '10px' }} /> Import collection
                    </Button>

                    <Button
                        variant="outlined"
                        onClick={handleViewLogs}
                        sx={{
                            background: 'white',
                            marginLeft: '20px'
                        }}
                    >
                        <TroubleshootIcon sx={{ marginRight: '10px' }} /> View Logs
                    </Button>
                </Grid>
            </Grid>
            
            <ProjectEndpointListComponent
                projectId={projectId}
                reload={reloadMockEndpoints}
                onAddEndpointToCollection={(endpoint) => handleOnAddEndpointToCollectionClicked(endpoint)}
            />

            {/* Add Endpoint to Collection Dialog */}
            {(endpointForCollection !== null || isImportCollection) && teamsWithCollections != null && teamsWithCollections.length !== 0 ?
                (
                    <Dialog
                        open={(endpointForCollection != null || isImportCollection)}
                        onClose={() => {
                            if (isImportCollection) {
                                handleOnImportCollectionDialogDismissed();
                            } else {
                                handleOnAddEndpointToCollectionDialogDismissed();
                            }
                        }}
                    >
                        <DialogTitle>
                            {isImportCollection ? (
                                "Import Collection to Project"
                            ) : (
                                "Assign Endpoint to a Collection"
                            )}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ marginBottom: 2 }}>
                                {isImportCollection ? (
                                    `Choose a collection from any team to import to this project.`
                                ) : (
                                    `Choose a collection from any team to assign the new endpoint '${endpointForCollection.name}' to. Collections provide a way to organize endpoints logically across different teams. You can also import these collections into any Mock Project later.`
                                )}
                            </DialogContentText>

                            <RadioGroup value={selectedTeamAndCollectionId} onChange={(event) => setSelectedTeamAndCollectionId(event.target.value)}>
                                {teamsWithCollections.map((team) => (
                                    <Card key={team._id} variant="outlined" style={{ marginBottom: 16 }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {team.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16, marginBottom: 1 }}>
                                                {team.description}
                                            </Typography>
                                            
                                            {team.collections.length > 0 ? (
                                                team.collections.map((collection) => (
                                                    <FormControlLabel
                                                        key={collection._id}
                                                        value={`${team._id}:${collection._id}`}
                                                        control={<Radio />}
                                                        label={
                                                            <Typography component="span">
                                                                {collection.name}
                                                                <Typography component="span" variant="body2" style={{ fontSize: '0.875rem', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.54)' }}>
                                                                    &nbsp;({collection.mockEndpointsCount} endpoints)
                                                                </Typography>
                                                            </Typography>
                                                        }
                                                        disabled={isImportCollection && collection.mockEndpointsCount === 0}
                                                        style={{ display: 'block', marginLeft: -11 }}
                                                    />
                                                ))
                                            ) : (
                                                <Typography color="textSecondary">No collections</Typography>
                                            )}
                                        </CardContent>
                                    </Card>
                                ))}
                            </RadioGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                if (isImportCollection) {
                                    handleOnImportCollectionDialogDismissed();
                                } else {
                                    handleOnAddEndpointToCollectionDialogDismissed();
                                }
                            }}
                            color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    if (isImportCollection) {
                                        confirmImportCollectionToProject();
                                    } else {
                                        confirmAddEndpointToCollection(endpointForCollection);
                                    }
                                }}
                                color="secondary"
                                variant="contained"
                                disabled={!selectedTeamAndCollectionId || isAddToCollectionInProgress}
                            >
                                {isAddToCollectionInProgress ? <CircularProgress size={24} /> : "Add to Collection"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                ) : null}
        </SideDrawer>
    );
}

export default ProjectEndpointListPage;