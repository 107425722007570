import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, Switch, Tooltip, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useProjects } from '../../../providers/ProjectsProvider';
import { useNavigate } from 'react-router';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ProjectEndpointCollapse from './ProjectEndpointCollapse';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PlaylistAdd } from '@mui/icons-material';

function ProjectEndpointCard({ projectId, endpoint, canEdit, onDuplicateClicked, onAddToCollectionClicked, onDeleteClicked, onIsEnabledSwitchChanged }) {
    const { setSelectedEndpoint } = useProjects();
    const [expanded, setExpanded] = useState(false);
    const [isEnableChangeInProgress, setIsEnableChangeInProgress] = useState(false);
    const navigate = useNavigate();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleEditEndpointClick = async () => {
        setSelectedEndpoint(endpoint);
        navigate(`/project/${projectId}/endpoints/${endpoint._id}/update`);
    };

    const handleIsEnabledSwitchChange = async (event) => {
        const newValue = event.target.checked;
        setIsEnableChangeInProgress(true);
        onIsEnabledSwitchChanged(newValue, () => {
            setIsEnableChangeInProgress(false);
        });
    };

    return (
        <Grid item xs={12}>
            <Card variant="outlined">
                <CardHeader
                    title={endpoint.name}
                    subheader={`${endpoint.targetEndpointRequestType} ${endpoint.targetEndpointUrl}`}
                    action={
                        <Box display='flex' alignItems='center'>
                            {canEdit ? (
                                <Tooltip
                                    title="Edit Endpoint"
                                >
                                    <Button
                                        aria-label="edit endpoint"
                                        variant="text"
                                        sx={{ marginLeft: "auto", marginRight: '10px' }}
                                        onClick={handleEditEndpointClick}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                            ) : null}

                            {onDuplicateClicked !== null ? (
                                <Tooltip
                                    title="Duplicate Endpoint"
                                >
                                    <Button
                                        aria-label="duplicate endpoint"
                                        variant="text"
                                        sx={{ marginRight: '10px' }}
                                        onClick={onDuplicateClicked}
                                    >
                                        <ContentCopyIcon />
                                    </Button>
                                </Tooltip>
                            ) : null}

                            {onAddToCollectionClicked !== null ? (
                                <Tooltip
                                    title="Add Endpoint to Collection"
                                >
                                    <Button
                                        aria-label="add endpoint to collection"
                                        variant="text"
                                        sx={{ marginRight: '10px' }}
                                        onClick={onAddToCollectionClicked}
                                    >
                                        <PlaylistAdd />
                                    </Button>
                                </Tooltip>
                            ) : null}

                            {onDeleteClicked !== null ? (
                                <Tooltip
                                    title="Delete Endpoint"
                                >
                                    <Button
                                        aria-label="delete endpoint"
                                        variant="text"
                                        sx={{ marginRight: '10px' }}
                                        onClick={onDeleteClicked}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>
                            ) : null}

                            {onIsEnabledSwitchChanged !== null ? (
                                <Tooltip
                                    title={`${endpoint.isEnabled ? 'Disable' : 'Enable'} Endpoint`}
                                >
                                    <Box sx={{ width: 60, height: 40 }} display='flex' alignItems='center'>
                                        {isEnableChangeInProgress ? (
                                            <CircularProgress size={20} thickness={5} sx={{ marginRight: 'auto', marginLeft: 'auto' }} />
                                        ) : (
                                            <Switch
                                                checked={endpoint.isEnabled}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                onChange={(event) => handleIsEnabledSwitchChange(event)}
                                            />
                                        )}
                                    </Box>
                                </Tooltip>
                            ) : null}
                        </Box>
                    }
                />

                <CardContent>
                    {endpoint.description ? (
                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
                            {endpoint.description}
                        </Typography>
                    ) : null}

                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
                        {`Hits: ${endpoint.hitsCount}${endpoint.hitsLimit >= 0 ? (` of ${endpoint.hitsLimit}`) : ''} ● Priority: ${endpoint.priority}`}
                    </Typography>

                    <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        Created: {formatDistanceToNow(new Date(endpoint.createdAt))} ago ● Last updated: {formatDistanceToNow(new Date(endpoint.updatedAt))} ago
                    </Typography>
                </CardContent>

                <CardActions>
                    <Button
                        aria-label="expand content"
                        variant="text"
                        onClick={handleExpandClick}
                        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        sx={{
                            marginLeft: 'auto',
                        }}
                    >
                        Show {expanded ? 'Less' : 'More'}
                    </Button>
                </CardActions>

                <ProjectEndpointCollapse endpoint={endpoint} expanded={expanded} />
            </Card>
        </Grid>
    );
};

export default ProjectEndpointCard;