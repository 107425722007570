import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useApi } from '../providers/ApiContext';

function UnauthorizedPage() {
    const navigate = useNavigate();
    const { performLogout, refreshUserDetails } = useApi();

    const handleMainRedirect = async () => {
        refreshUserDetails(
            (user) => {
                navigate('/teams');
            },
            (error) => { }
        );
    };

    const handleSignOut = async () => {
        performLogout();
        handleMainRedirect();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} textAlign="center">
                        <img
                            src="/logo-unauthorised.jpeg"
                            alt=""
                            height={300}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h4">
                            REGISTERED
                        </Typography>
                        <Typography variant="h5" sx={{ fontStyle: 'italic', marginLeft: 1, marginRight: 1 }}>
                            but
                        </Typography>
                        <Typography variant="h4">
                            UNAUTHORIZED
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h6">
                            Thank you for registering. Please contact an administrator for access privileges.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" sx={{ marginTop: 2 }}>
                        <Button variant="contained" onClick={handleMainRedirect}>
                            Try Again
                        </Button>
                    </Grid>
                    <Grid item xs={12} textAlign="center" sx={{ marginBottom: 15 }}>
                        <Button variant="contained" onClick={handleSignOut}>
                            Sign Out
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default UnauthorizedPage;
