import { Typography } from "@mui/material";
import packageJson from '../../../package.json';

function Copyright(props) {
    const appVersion = packageJson.version;
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {`© ${new Date().getFullYear()} ${process.env.REACT_APP_WEBSITE_NAME} | Version ${appVersion}`}
        </Typography>
    );
}

export default Copyright;