import React, { useEffect, useState } from 'react';
import { useApi } from '../../providers/ApiContext';
import { Box, Button, Card, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import ProjectEndpointCard from './cells/ProjectEndpointCard';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useProjects } from '../../providers/ProjectsProvider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDistanceToNow } from 'date-fns';

function ProjectEndpointListComponent({ projectId, reload, onAddEndpointToCollection }) {
    const [projectEndpoints, setProjectEndpoints] = useState([]);
    const [deleteDialogEndpointId, setDeleteDialogEndpointId] = useState(null);
    const [loading, setLoading] = useState(false);
    const { axiosInstance } = useApi();
    const navigate = useNavigate();
    const { getProjectById, setSelectedDuplicateEndpoint } = useProjects();
    const project = getProjectById(projectId);

    // Fetch project's mock endpoints
    const fetchProjectEndpoints = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/v1/admin/project/${projectId}/endpoint`);
            setProjectEndpoints(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error('Loading mock endpoints failed, please try again later.');
        }
    };

    useEffect(() => {
        fetchProjectEndpoints();
    }, [projectId, reload]);

    const handleOnDeleteClicked = async (endpointId) => {
        setDeleteDialogEndpointId(endpointId);
    };

    const performEndpointDeletion = async (endpointId) => {
        try {
            const response = await axiosInstance.delete(`/v1/admin/project/${projectId}/endpoint/${endpointId}`);
            setProjectEndpoints((prevProjectEndpoints) => prevProjectEndpoints.filter((endpoint) => endpoint._id !== endpointId));
            setDeleteDialogEndpointId(null);
            toast.success('Successfully deleted');
        } catch (error) {
            setDeleteDialogEndpointId(null);
            toast.error('Deletion failed');
        }
    };

    const removeIdKeys = (obj) => {
        if (typeof obj === 'object' && obj !== null) {
            if (Array.isArray(obj)) {
                for (let i = 0; i < obj.length; i++) {
                    obj[i] = removeIdKeys(obj[i]);
                }
            } else {
                for (const key in obj) {
                    if (key === '_id') {
                        delete obj[key];
                    } else {
                        obj[key] = removeIdKeys(obj[key]);
                    }
                }
            }
        }
        return obj;
    };

    const handleOnDuplicateClicked = async (endpointId) => {
        const selectedEndpoint = projectEndpoints.find((endpoint) => endpoint._id === endpointId);
        if (selectedEndpoint) {
            const selectedEndpointWithoutId = removeIdKeys(selectedEndpoint);

            selectedEndpointWithoutId.name = `Copy - ${selectedEndpointWithoutId.name}`;
            setSelectedDuplicateEndpoint(selectedEndpointWithoutId);
            navigate(`/project/${projectId}/endpoints/create`);
        } else {
            toast.error('Sorry, something went wrong. Please refresh the page and try again.');
        }
    };

    const handleOnIsEnabledSwitchChanged = async (endpointId, updatedIsEnabled, onCompletionCallback) => {
        try {
            const response = await axiosInstance.patch(`/v1/admin/project/${projectId}/endpoint/${endpointId}`, { isEnabled: updatedIsEnabled });
            setProjectEndpoints(response.data.mockEndpoints);
            toast.success(`Successfully ${updatedIsEnabled ? 'enabled' : 'disabled'}`);
            onCompletionCallback();
        } catch (error) {
            setDeleteDialogEndpointId(null);
            toast.error('Status change failed');
            onCompletionCallback();
        }
    }

    // Function to copy the proxy base URL to the clipboard
    const copyToClipboard = () => {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/v1/proxy/${projectId}`;
        const textArea = document.createElement('textarea');
        textArea.value = baseUrl;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        toast.info('Proxy base URL copied to clipboard');
    };

    return (
        <div>
            {/* Project Information */}
            <Divider sx={{ marginBottom: 4 }}><Chip variant="outlined" label="Project Details" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5">{project.name}</Typography>
                    <Typography variant="body1" color="text.secondary">{project.description}</Typography>
                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    <Typography variant="body1" color="text.secondary">Target URL: {project.targetBaseUrl}</Typography>
                    <Typography variant="body1" color="text.secondary">Proxy Base URL: {`${process.env.REACT_APP_API_BASE_URL}/v1/proxy/${projectId}`} <ContentCopyIcon sx={{ height: 15 }} onClick={copyToClipboard} /></Typography>
                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                        Created: {formatDistanceToNow(new Date(project.createdAt))} ago ● Last updated: {formatDistanceToNow(new Date(project.updatedAt))} ago
                    </Typography>
                </CardContent>
            </Card>

            <Divider sx={{ marginTop: 4, marginBottom: 4 }}><Chip variant="outlined" label="Mock Endpoints" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>

            {loading ? (
                <Box
                    sx={{ width: '100%', marginTop: '40px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            ) : (
                projectEndpoints.length === 0 ? (
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} textAlign="center">
                            <img
                                src="/empty-box.png"
                                alt=""
                                height={150}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h5">
                                No mock endpoints
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Typography>
                                Feel free to create a new mock endpoint for this project and it will get listed here.
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {projectEndpoints.map((endpoint) => (
                            <ProjectEndpointCard
                                key={endpoint._id}
                                projectId={projectId}
                                endpoint={endpoint}
                                canEdit={true}
                                onDuplicateClicked={() => { handleOnDuplicateClicked(endpoint._id); }}
                                onAddToCollectionClicked={() => { onAddEndpointToCollection(endpoint); }}
                                onDeleteClicked={() => { handleOnDeleteClicked(endpoint._id); }}
                                onIsEnabledSwitchChanged={(updatedValue, onCompletionCallback) => { handleOnIsEnabledSwitchChanged(endpoint._id, updatedValue, onCompletionCallback); }}
                            />
                        ))}
                    </Grid>
                )
            )}
            
            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogEndpointId !== null}
                onClose={() => setDeleteDialogEndpointId(null)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this endpoint?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogEndpointId(null)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => performEndpointDeletion(deleteDialogEndpointId)}
                        color="secondary"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ProjectEndpointListComponent;