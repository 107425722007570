import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectEndpointListComponent from '../../components/endpoints/ProjectEndpointListComponent';
import { Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, List, ListItem, ListItemText, Radio, RadioGroup, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SideDrawer from '../../components/common/SideDrawer';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';
import { useProjects } from '../../providers/ProjectsProvider';
import { toast } from 'react-toastify';
import { useApi } from '../../providers/ApiContext';
import CollectionEndpointListComponent from '../../components/collections/CollectionEndpointListComponent';

function CollectionEndpointListPage() {
    const navigate = useNavigate();
    const { teamId, collectionId } = useParams();
    const { selectedTeam, selectedCollection } = useProjects();

    useEffect(() => {
        if (!selectedCollection || selectedCollection._id !== collectionId) {
            navigate(`/team/${selectedTeam._id}/collections`);
        }
    }, []);

    const handleBackNavigation = async () => {
        navigate(-1);
    };

    return (
        <SideDrawer title="Collection's Mock Endpoints">
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>
                
                <NavBreadcrumbs
                    currentPageTitle="Endpoints"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        },
                        {
                            title: "Projects",
                            path: `/team/${selectedTeam._id}/projects`
                        },
                        {
                            title: "Collections",
                            path: `/team/${selectedTeam._id}/collections`
                        }
                    ]}
                />
            </Grid>
            <CollectionEndpointListComponent
                teamId={teamId}
                collection={selectedCollection}
            />
        </SideDrawer>
    );
}

export default CollectionEndpointListPage;