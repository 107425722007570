import React from 'react';
import { ApiProvider } from './providers/ApiContext';
import AppRoutes from './AppRoutes';
import { ProjectsProvider } from './providers/ProjectsProvider';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
};
const defaultTheme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <ApiProvider>
          <ProjectsProvider>
            <AppRoutes />
          </ProjectsProvider>
        </ApiProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
