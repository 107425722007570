import React, { useEffect, useRef, useState } from 'react';
import { useApi } from '../../providers/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../../providers/ProjectsProvider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Box, Button, CircularProgress, Container, Divider, Grid, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';

function TeamListComponent() {
    const navigate = useNavigate();
    const { teams, setTeams, setSelectedTeam } = useProjects();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [deleteDialogTeamId, setDeleteDialogTeamId] = useState(null);
    const { axiosInstance } = useApi();

    // Fetch teams
    const fetchTeams = async () => {
        try {
            const response = await axiosInstance.get(`/v1/admin/team`);
            setTeams(response.data);
            setLoading(false);
            setLoaded(true);
        } catch (error) {
            toast.error('Sorry, an error occurred. We were not able to retrieve the list of teams. Please try again later.');
            setLoading(false);
            setLoaded(true);
        }
    };

    useEffect(() => {
        if (!loading && !loaded) {
            setLoading(true);
            fetchTeams();
        }
    }, []);

    const confirmDelete = async (teamId) => {
        try {
            const response = await axiosInstance.delete(`/v1/admin/team/${teamId}`);

            // Remove the deleted project from the projects list
            setTeams((prevTeams) => prevTeams.filter((team) => team._id !== teamId));

            setDeleteDialogTeamId(null);
            toast.success('Successfully deleted');
        } catch (error) {
            setDeleteDialogTeamId(null);
            toast.error('Deletion failed');
        }
    };

    const handleEditTeamClick = (team) => {
        setSelectedTeam(team);
        navigate(`/team/${team._id}/update`);
    };

    const handleViewTeamProjects = (team) => {
        setSelectedTeam(team);
        navigate(`/team/${team._id}/projects`);
    };

    return (
        <div>
            {teams.length === 0 && !loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'top',
                        minHeight: '100vh',
                        marginTop: '100px',
                    }}
                >
                    <Container maxWidth="md">
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} textAlign="center">
                                <img
                                    src="/empty-box.png"
                                    alt=""
                                    height={150}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h5">
                                    No teams
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography>
                                    Feel free to create a new team and it will get listed here.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {teams.map((team) => (
                        <Grid item xs={4} key={team._id}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {team.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
                                        {team.description}
                                    </Typography>

                                    <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                        Has {team.collectionsCount} collection{team.collectionsCount == 0 ? 's' : ''}.
                                    </Typography>
                                </CardContent>

                                <Divider sx={{ marginRight: 2, marginLeft: 2 }} />

                                <CardActions>
                                    <Tooltip
                                        title="View projects"
                                    >
                                        <Button
                                            aria-label="view projects"
                                            variant="text"
                                            sx={{ marginLeft: "auto" }}
                                            onClick={() => { handleViewTeamProjects(team) }}
                                        >
                                            <VisibilityIcon sx={{ marginRight: '10px' }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        title="Edit team"
                                    >
                                        <Button
                                            aria-label="edit team"
                                            variant="text"
                                            sx={{ marginLeft: "auto", marginRight: '10px' }}
                                            onClick={() => { handleEditTeamClick(team) }}
                                        >
                                            <EditIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        title="Delete team"
                                    >
                                        <Button
                                            aria-label="delete team"
                                            variant="text"
                                            sx={{ marginRight: "auto" }}
                                            onClick={() => { setDeleteDialogTeamId(team._id) }}
                                        >
                                            <DeleteIcon sx={{ marginRight: '10px' }} />
                                        </Button>
                                    </Tooltip>
                                </CardActions>

                                {/* Delete Confirmation Dialog */}
                                <Dialog
                                    open={deleteDialogTeamId != null && deleteDialogTeamId == team._id}
                                    onClose={() => setDeleteDialogTeamId(null)}
                                >
                                    <DialogTitle>Confirm Deletion</DialogTitle>
                                    <DialogContent>
                                        Are you sure you want to delete this team and all its associated projects?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setDeleteDialogTeamId(null)} color="primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => confirmDelete(team._id)}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
            {(loading) &&
                <Box
                    sx={{ width: '100%', marginTop: '40px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            }
        </div>
    );
}

export default TeamListComponent;