import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useApi } from "../../providers/ApiContext";
import { Typography, TextField, Button, Container, Box, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { toast } from "react-toastify";

function TeamCreateUpdateComponent({ existingTeam }) {
    const navigate = useNavigate();
    const [name, setName] = useState(existingTeam ? existingTeam.name : '');
    const [description, setDescription] = useState(existingTeam ? existingTeam.description : '');
    const { axiosInstance } = useApi();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (existingTeam) {
                const updateTeamResponse = await axiosInstance.patch(`/v1/admin/team/${existingTeam._id}`, {
                    name,
                    description,
                });

                // Successful response, navigate back to list of teams
                navigate(-1);
            } else {
                const createTeamResponse = await axiosInstance.post(`/v1/admin/team`, {
                    name,
                    description,
                });

                // Successful response, navigate to the new team's projects
                navigate(`/team/${createTeamResponse.data.id}/projects`, { replace: true });
            }
        } catch (error) {
            toast.error(`Sorry, your team couldn't be ${existingTeam ? 'updated' : 'created'}. Please make sure all required fields have been set before trying again.`);
        }
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                {existingTeam ? 'Update Team Details' : 'Create a New Team'}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                {
                    existingTeam ?
                        "Change the details below to update this team"
                        :
                        "Enter the details below to create a new team"
                }
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ background: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ background: 'white' }}
                        />
                    </Grid>
                </Grid>
                <Box mt={2} marginTop={4} display="flex" justifyContent="center">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {existingTeam ? 'Update' : 'Create'} Team
                    </Button>
                </Box>
            </form>
        </Container>
    );
}

export default TeamCreateUpdateComponent;
