import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useApi } from './ApiContext';

function ProtectedRoute() {
    const location = useLocation();
    const { isAuthenticated, user } = useApi();

    if (isAuthenticated && user && user.role === "admin") {
        return <Outlet />;
    } else if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
}

export default ProtectedRoute;
