import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectCreateUpdateEndpointComponent from '../../components/endpoints/ProjectCreateUpdateEndpointComponent';
import { useProjects } from '../../providers/ProjectsProvider';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TeamCreateUpdateComponent from '../../components/teams/TeamCreateUpdateComponent';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';

function TeamUpdatePage() {
    const navigate = useNavigate();
    const { teamId } = useParams();
    const { selectedTeam } = useProjects();

    useEffect(() => {
        if (!selectedTeam || selectedTeam._id !== teamId) {
            navigate('/teams');
        }
    }, []);

    const handleBackNavigation = async () => {
        navigate(-1);
    };

    return (
        <SideDrawer title='Update Team Details'>
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>

                <NavBreadcrumbs
                    currentPageTitle="Update Team"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        }
                    ]}
                />
            </Grid>
            <TeamCreateUpdateComponent existingTeam={selectedTeam} />
        </SideDrawer>
    );
}

export default TeamUpdatePage;