import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const NavBreadcrumbs = ({ currentPageTitle, history }) => {
  const breadcrumbs = [];
  history.forEach((element, index) => {
    breadcrumbs.push(
      <Link underline="hover" key={index} color="inherit" href={element.path}>
        {element.title}
      </Link>
    );
  });
  breadcrumbs.push(
    <Typography key={breadcrumbs.length} color="text.primary">
      {currentPageTitle}
    </Typography>
  );

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}

export default NavBreadcrumbs;