import React from 'react';
import { useNavigate } from 'react-router';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TeamCreateUpdateComponent from '../../components/teams/TeamCreateUpdateComponent';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';

function TeamCreatePage() {
    const navigate = useNavigate();

    const handleBackNavigation = async () => {
        navigate(-1);
    };

    return (
        <SideDrawer title='Create New Team'>
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>

                <NavBreadcrumbs
                    currentPageTitle="Create Team"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        }
                    ]}
                />
            </Grid>
            <TeamCreateUpdateComponent />
        </SideDrawer>
    );
}

export default TeamCreatePage;
