import React, { useEffect, useRef, useState } from 'react';
import { useApi } from '../../providers/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../../providers/ProjectsProvider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Box, Button, CircularProgress, Container, Grid, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';

function ProjectListComponent({ teamId }) {
    const navigate = useNavigate();
    const { projects, setProjects, setSelectedProject } = useProjects();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [observerEnabled, setObserverEnabled] = useState(true);
    const [deleteDialogProjectId, setDeleteDialogProjectId] = useState(null);
    const { axiosInstance } = useApi();

    const observerRef = useRef();

    // Fetch projects
    const fetchProjects = async (page) => {
        try {
            if (page == 1) {
                setProjects([])
            }

            const response = await axiosInstance.get(`/v1/admin/team/${teamId}/project?page=${page}&limit=5`);
            const { results, totalPages: responseTotalPages } = response.data;

            setProjects((prevProjects) => [...prevProjects, ...results]);
            setTotalPages(responseTotalPages);

            if (currentPage >= responseTotalPages) {
                setObserverEnabled(false);
            }

            setLoading(false);
        } catch (error) {
            toast.error('Sorry, an error occurred. We were not able to retrieve the list of projects. Please try again later.');
        }
    };

    // Intersection observer callback
    const handleObserver = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && observerEnabled) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        if (!loading && currentPage <= totalPages) {
            setLoading(true);
            fetchProjects(currentPage);
        }
    }, [currentPage, totalPages]);

    useEffect(() => {
        // Initialize IntersectionObserver
        observerRef.current = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Adjust the threshold as needed
        });

        // Attach observer to the loading element
        if (observerRef.current) {
            observerRef.current.observe(document.getElementById('loading'));
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [observerEnabled]);

    const confirmDelete = async (projectId) => {
        try {
            const response = await axiosInstance.delete(`/v1/admin/project/${projectId}`);

            // Remove the deleted project from the projects list
            setProjects((prevProjects) => prevProjects.filter((project) => project.id !== projectId));

            setDeleteDialogProjectId(null);
            toast.success('Successfully deleted');
        } catch (error) {
            setDeleteDialogProjectId(null);
            toast.error('Deletion failed');
        }
    };

    const handleEditProjectClick = (project) => {
        setSelectedProject(project);
        navigate(`/project/${project.id}/update`);
    };

    const handleViewProjectEndpoints = (projectId) => {
        navigate(`/project/${projectId}/endpoints`)
    };

    return (
        <div>
            {projects.length === 0 && !loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'top',
                        minHeight: '100vh',
                        marginTop: '100px',
                    }}
                >
                    <Container maxWidth="md">
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} textAlign="center">
                                <img
                                    src="/empty-box.png"
                                    alt=""
                                    height={150}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h5">
                                    No projects
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography>
                                    Feel free to create a new project and it will get listed here.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {projects.map((project) => (
                        <Grid item xs={6} key={project.id}>
                            <Card variant="outlined">
                                <CardHeader
                                    title={project.name}
                                    subheader={project.targetBaseUrl}
                                    action={
                                        <div>
                                            <Tooltip
                                                title="View endpoints"
                                            >
                                                <Button
                                                    aria-label="view endpoints"
                                                    variant="text"
                                                    sx={{ marginLeft: "auto" }}
                                                    onClick={() => { handleViewProjectEndpoints(project.id) }}
                                                >
                                                    <VisibilityIcon sx={{ marginRight: '10px' }} />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Edit project details"
                                            >
                                                <Button
                                                    aria-label="edit project"
                                                    variant="text"
                                                    sx={{ marginLeft: "auto", marginRight: '10px' }}
                                                    onClick={() => { handleEditProjectClick(project) }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Delete project"
                                            >
                                                <Button
                                                    aria-label="delete project"
                                                    variant="text"
                                                    sx={{ marginRight: "auto" }}
                                                    onClick={() => { setDeleteDialogProjectId(project.id) }}
                                                >
                                                    <DeleteIcon sx={{ marginRight: '10px' }} />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    }
                                />
                                <CardContent>
                                    {project.description ? (
                                        <Typography variant="body2" color="text.secondary">
                                            {project.description}
                                        </Typography>
                                    ) : null}
                                    <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                        Last updated: {formatDistanceToNow(new Date(project.updatedAt))} ago
                                    </Typography>
                                </CardContent>

                                {/* Delete Confirmation Dialog */}
                                <Dialog
                                    open={deleteDialogProjectId == project.id}
                                    onClose={() => setDeleteDialogProjectId(null)}
                                >
                                    <DialogTitle>Confirm Deletion</DialogTitle>
                                    <DialogContent>
                                        Are you sure you want to delete this project?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setDeleteDialogProjectId(null)} color="primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => confirmDelete(project.id)}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
            {(loading) &&
                <Box
                    sx={{ width: '100%', marginTop: '40px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            }
            <div id="loading" style={{ height: '10px' }}></div>
        </div>
    );
}

export default ProjectListComponent;