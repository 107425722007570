import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useApi } from "../../providers/ApiContext";
import { Typography, TextField, Button, Container, Box, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useProjects } from "../../providers/ProjectsProvider";
import { toast } from "react-toastify";

function ProjectCreateUpdateComponent({ teamId, existingProject }) {
    const navigate = useNavigate();
    const { projects, setProjects } = useProjects();
    const [name, setName] = useState(existingProject ? existingProject.name : '');
    const [description, setDescription] = useState(existingProject ? existingProject.description : '');
    const [targetBaseUrl, setTargetBaseUrl] = useState(existingProject ? existingProject.targetBaseUrl : '');
    const { axiosInstance } = useApi();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (existingProject) {
                const updateProjectResponse = await axiosInstance.patch(`/v1/admin/project/${existingProject.id}`, {
                    name,
                    description,
                    targetBaseUrl,
                });

                // Successful response, navigate back to list of projects
                navigate(-1);
            } else {
                const createProjectResponse = await axiosInstance.post(`/v1/admin/team/${teamId}/project`, {
                    name,
                    description,
                    targetBaseUrl,
                });
    
                // Cache new project
                setProjects([...projects, createProjectResponse.data]);
    
                // Successful response, navigate to the new project's details
                navigate(`/project/${createProjectResponse.data.id}/endpoints`, { replace: true });
            }
        } catch (error) {
            toast.error(`Sorry, your project couldn't be ${existingProject ? 'updated' : 'created'}. Please make sure all required fields have been set before trying again.`);
        }
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                {existingProject ? 'Update Project Details' : 'Create a New Project'}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                {
                    existingProject ?
                        "Change the details below to update this project"
                        :
                        "Enter the details below to create a new project"
                }
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ background: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ background: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <TextField
                            label="Target Base URL"
                            variant="outlined"
                            fullWidth
                            value={targetBaseUrl}
                            onChange={(e) => setTargetBaseUrl(e.target.value)}
                            sx={{ background: 'white' }}
                        />
                    </Grid>
                </Grid>
                <Box mt={2} marginTop={4} display="flex" justifyContent="center">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {existingProject ? 'Update' : 'Create'} Project
                    </Button>
                </Box>
            </form>
        </Container>
    );
}

export default ProjectCreateUpdateComponent;
