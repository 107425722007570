
import Collapse from '@mui/material/Collapse';
import TreeView from '@mui/lab/TreeView';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { JsonEditor } from 'jsoneditor-react18';
import { Box, CardContent, Chip, Divider, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

function ProjectEndpointCollapse({ endpoint, expanded }) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#999999',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <Divider><Chip variant="outlined" label="Frequency" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>


                <Box display="flex" alignItems="center">
                    <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
                        Hits Count: {endpoint.hitsCount}
                    </Typography>
                    <Tooltip
                        title="Hit Count: This value represents how many times a request to this mock endpoint has been made, and the mocked response was sent back in response. It tracks the number of successful responses provided by the mock endpoint."
                    >
                        <InfoIcon sx={{ height: 20, color: 'grey', marginLeft: 1 }} />
                    </Tooltip>
                </Box>

                <Box display="flex" alignItems="center">
                    {endpoint.hitsLimit >= 0 ? (
                        <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
                            Hits Limit: {endpoint.hitsLimit}
                        </Typography>
                    ) : (
                        <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
                            Hit Limit: Unlimited
                        </Typography>
                    )}
                    <Tooltip
                        title="Hits Limit: This value represents the maximum number of times the mocked response will be returned. Once this limit is reached, the mock endpoint will be considered as disabled, and subsequent requests will result in a simple proxy response instead. If the value is set to -1, it means there is no limit, and the mock response will be returned an unlimited number of times."
                        sx={{ marginRight: 1 }}
                    >
                        <InfoIcon sx={{ height: 20, color: 'grey', marginLeft: 1 }} />
                    </Tooltip>
                </Box>

                <Divider><Chip variant="outlined" label="Request Criteria" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>
                <TreeView
                    aria-label="mock criteria navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{
                        fontSize: 16,
                        color: 'rgba(0, 0, 0, 0.6)',
                    }}
                >
                    {endpoint.urlParametersCriteria && endpoint.urlParametersCriteria.length > 0 ? (
                        <TreeItem nodeId={`${endpoint._id}_1`} label="Query Parameters">
                            {endpoint.urlParametersCriteria.map((urlParamCriteria) => (
                                urlParamCriteria.description ? (
                                    <TreeItem key={urlParamCriteria._id} nodeId={urlParamCriteria._id} label={`Parameter "${urlParamCriteria.parameterKey}" must have value "${urlParamCriteria.expectedParameterValue}"`}>
                                        <TreeItem nodeId={`${urlParamCriteria._id}_description`} label={`Description: ${urlParamCriteria.description}`} />
                                    </TreeItem>
                                ) : (
                                    <TreeItem key={urlParamCriteria._id} nodeId={urlParamCriteria._id} label={`Parameter "${urlParamCriteria.parameterKey}" must have value "${urlParamCriteria.expectedParameterValue}"`} />
                                )
                            ))}
                        </TreeItem>
                    ) : (
                        <TreeItem nodeId={`${endpoint._id}_1`} label="Query Parameters">
                            <TreeItem nodeId={`${endpoint._id}_1.1`} label="Any parameter is allowed" />
                        </TreeItem>
                    )}

                    {endpoint.headersCriteria && endpoint.headersCriteria.length > 0 ? (
                        <TreeItem nodeId={`${endpoint._id}_2`} label="Request Headers">
                            {endpoint.headersCriteria.map((headerCriteria) => (
                                headerCriteria.description ? (
                                    headerCriteria.jwtCriteria && headerCriteria.jwtCriteria.length > 0 ? (
                                        <TreeItem key={headerCriteria._id} nodeId={headerCriteria._id} label={`Header "${headerCriteria.headerKey}" must be a JSON Web Token (JWT) with the following fields...`}>
                                            <TreeItem nodeId={`${headerCriteria._id}_description`} label={`Description: ${headerCriteria.description}`} />
                                            {headerCriteria.jwtCriteria.map((jwtCriteria) => (
                                                <TreeItem key={jwtCriteria._id} nodeId={jwtCriteria._id} label={`Field "${jwtCriteria.fieldKey}" must have value "${jwtCriteria.expectedFieldValue}"`} />
                                            ))}
                                        </TreeItem>
                                    ) : (
                                        <TreeItem key={headerCriteria._id} nodeId={headerCriteria._id} label={`Header "${headerCriteria.headerKey}" must have value "${headerCriteria.expectedHeaderValue}"`}>
                                            <TreeItem nodeId={`${headerCriteria._id}_description`} label={`Description: ${headerCriteria.description}`} />
                                        </TreeItem>
                                    )
                                ) : (
                                    headerCriteria.jwtCriteria && headerCriteria.jwtCriteria.length > 0 ? (
                                        <TreeItem key={headerCriteria._id} nodeId={headerCriteria._id} label={`Header "${headerCriteria.headerKey}" must be a JSON Web Token (JWT) with the following fields...`}>
                                            {headerCriteria.jwtCriteria.map((jwtCriteria) => (
                                                <TreeItem key={jwtCriteria._id} nodeId={jwtCriteria._id} label={`Field "${jwtCriteria.fieldKey}" must have value "${jwtCriteria.expectedFieldValue}"`} />
                                            ))}
                                        </TreeItem>
                                    ) : (
                                        <TreeItem key={headerCriteria._id} nodeId={headerCriteria._id} label={`Header "${headerCriteria.headerKey}" must have value "${headerCriteria.expectedHeaderValue}"`} />
                                    )
                                )
                            ))}
                        </TreeItem>
                    ) : (
                        <TreeItem nodeId={`${endpoint._id}_2`} label="Request Headers">
                            <TreeItem nodeId={`${endpoint._id}_2.1`} label="Any header is allowed" />
                        </TreeItem>
                    )}
                </TreeView>

                <Divider><Chip variant="outlined" label="Mocked Response" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>
                <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
                    HTTP Status: {endpoint.mockResponseHttpStatus}
                </Typography>
                {endpoint.mockResponsePayload ? (
                    <div>
                        <Typography variant="body1" color="text.secondary">
                            Payload:
                        </Typography>

                        <JsonEditor
                            value={JSON.parse(endpoint.mockResponsePayload)}
                            mode='view'
                            navigationBar={false}
                        />
                    </div>
                ) : (
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>Payload: None</Typography>
                )}

                {endpoint.mockResponseHeaders && endpoint.mockResponseHeaders.length > 0 ? (
                    <div>
                        <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
                            Response Headers:
                        </Typography>
                        <TableContainer
                            sx={{
                                border: 'thin solid #999999'
                            }}
                        >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Header Key</StyledTableCell>
                                        <StyledTableCell>Header Value</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {endpoint.mockResponseHeaders.map((responseHeader) => (
                                        <StyledTableRow
                                            key={responseHeader.key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell component="th" scope="row">
                                                {responseHeader.key}
                                            </StyledTableCell>
                                            <StyledTableCell>{responseHeader.value}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                ) : (
                    <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
                        Response Headers: None
                    </Typography>
                )}
            </CardContent>
        </Collapse>
    );
}

export default ProjectEndpointCollapse;