import React, { useEffect, useState } from 'react';
import { useApi } from '../../providers/ApiContext';
import { Box, Button, Card, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useProjects } from '../../providers/ProjectsProvider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDistanceToNow } from 'date-fns';
import ProjectEndpointCard from '../endpoints/cells/ProjectEndpointCard';

function CollectionEndpointListComponent({ teamId, collection }) {
    const navigate = useNavigate();
    const { axiosInstance } = useApi();
    const [collectionEndpoints, setCollectionEndpoints] = useState([]);
    const [deleteDialogEndpointId, setDeleteDialogEndpointId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch collection's mock endpoints
        const fetchCollectionEndpoints = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get(`/v1/admin/team/${teamId}/collection/${collection._id}/endpoint`);
                setCollectionEndpoints(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error("Loading collection's mock endpoints failed, please try again later.");
            }
        };

        fetchCollectionEndpoints();
    }, [teamId, collection]);

    const handleOnDeleteClicked = async (endpointId) => {
        setDeleteDialogEndpointId(endpointId);
    };

    const performEndpointDeletion = async (endpointId) => {
        try {
            const response = await axiosInstance.delete(`/v1/admin/team/${teamId}/collection/${collection._id}/endpoint/${endpointId}`);
            setCollectionEndpoints((prevCollectionEndpoints) => prevCollectionEndpoints.filter((endpoint) => endpoint._id !== endpointId));
            setDeleteDialogEndpointId(null);
            toast.success('Mock endpoint successfully deleted from collection.');
        } catch (error) {
            setDeleteDialogEndpointId(null);
            toast.error('Failed to delete mock endpoint from collection. Please try again later.');
        }
    };

    return (
        <div>
            {/* Project Information */}
            <Divider sx={{ marginBottom: 4 }}><Chip variant="outlined" label="Collection Details" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5">{collection.name}</Typography>
                    <Typography variant="body1" color="text.secondary">{collection.description}</Typography>
                </CardContent>
            </Card>

            <Divider sx={{ marginTop: 4, marginBottom: 4 }}><Chip variant="outlined" label="Mock Endpoints" sx={{ fontSize: 16, fontWeight: 'bold' }} /></Divider>

            {loading ? (
                <Box
                    sx={{ width: '100%', marginTop: '40px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            ) : (
                collectionEndpoints.length === 0 ? (
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} textAlign="center">
                            <img
                                src="/empty-box.png"
                                alt=""
                                height={150}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h5">
                                No mock endpoints for this collection
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Typography>
                            To add mock endpoints to this collection, please navigate to the list of mock endpoints in any project. From there, you can assign endpoints to this or any other collection.
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {collectionEndpoints.map((endpoint) => (
                            <ProjectEndpointCard
                                key={endpoint._id}
                                endpoint={endpoint}
                                canEdit={false}
                                onDuplicateClicked={null}
                                onAddToCollectionClicked={null}
                                onDeleteClicked={() => { handleOnDeleteClicked(endpoint._id); }}
                                onIsEnabledSwitchChanged={null}
                            />
                        ))}
                    </Grid>
                )
            )}
            
            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogEndpointId !== null}
                onClose={() => setDeleteDialogEndpointId(null)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this endpoint from the collection?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogEndpointId(null)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => performEndpointDeletion(deleteDialogEndpointId)}
                        color="secondary"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CollectionEndpointListComponent;