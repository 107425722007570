import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useApi } from './providers/ApiContext';
import LoginPage from './pages/auth/LoginPage';
import RegistrationPage from './pages/auth/RegistrationPage';
import ProtectedRoute from './providers/ProtectedRoute';
import ProjectListPage from './pages/projects/ProjectListPage';
import NotFoundPage from './pages/NotFoundPage';
import ProjectCreatePage from './pages/projects/ProjectCreatePage';
import ProjectEndpointListPage from './pages/endpoints/ProjectEndpointListPage';
import ProjectCreateNewEndpointPage from './pages/endpoints/ProjectCreateNewEndpointPage';
import ProjectUpdateExistingEndpointPage from './pages/endpoints/ProjectUpdateExistingEndpointPage';
import TeamCreatePage from './pages/teams/TeamCreatePage';
import TeamListPage from './pages/teams/TeamListPage';
import TeamUpdatePage from './pages/teams/TeamUpdatePage';
import ProjectUpdatePage from './pages/projects/ProjectUpdatePage';
import UserListPage from './pages/users/UserListPage';
import UnauthorizedPage from './pages/UnauthorizedPage';
import ProjectViewLogsPage from './pages/logs/ProjectViewLogsPage';
import CollectionListPage from './pages/collections/CollectionListPage';
import CollectionEndpointListPage from './pages/collections/CollectionEndpointListPage';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/" element={<ProtectedRoute />}>
                <Route index element={<Navigate replace to="/teams" />} />
                <Route path="/teams" element={<TeamListPage />} />
                <Route path="/team/create" element={<TeamCreatePage />} />
                <Route path="/team/:teamId/update" element={<TeamUpdatePage />} />
                <Route path="/team/:teamId/projects" element={<ProjectListPage />} />
                <Route path="/team/:teamId/project/create" element={<ProjectCreatePage />} />
                <Route path="/team/:teamId/collections" element={<CollectionListPage />} />
                <Route path="/team/:teamId/collection/:collectionId/endpoints" element={<CollectionEndpointListPage />} />
                <Route path="/project/:projectId/logs" element={<ProjectViewLogsPage />} />
                <Route path="/project/:projectId/update" element={<ProjectUpdatePage />} />
                <Route path="/project/:projectId/endpoints" element={<ProjectEndpointListPage />} />
                <Route path="/project/:projectId/endpoints/create" element={<ProjectCreateNewEndpointPage />} />
                <Route path="/project/:projectId/endpoints/:endpointId/update" element={<ProjectUpdateExistingEndpointPage />} />
                <Route path="/users" element={<UserListPage />} />
            </Route>
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

export default AppRoutes;
