import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useProjects } from '../../providers/ProjectsProvider';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';
import CollectionListComponent from '../../components/collections/CollectionListComponent';
import { toast } from 'react-toastify';
import { useApi } from '../../providers/ApiContext';

function CollectionListPage() {
  const navigate = useNavigate();
  const { axiosInstance } = useApi();
  const { teamId } = useParams();
  const { selectedTeam } = useProjects();
  const [openDialog, setOpenDialog] = useState(false);
  const [collectionName, setCollectionName] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [reloadCollections, setReloadCollections] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);

  const handleBackNavigation = async () => {
    navigate(-1);
  };

  const handleCreateNewCollection = () => {
    setOpenDialog(true);
    setIsEditing(false);
    setCollectionName('');
    setCollectionDescription('');
  };

  const handleEditCollection = ({ _id, name, description }) => {
    setCurrentCollectionId(_id);
    setCollectionName(name);
    setCollectionDescription(description);
    setOpenDialog(true);
    setIsEditing(true);
  };

  const handleCloseDialog = () => {
    if (!loading) { // Prevent closing if loading
      setOpenDialog(false);
      setCollectionName('');
      setCollectionDescription('');
    }
  };

  const handleSaveCollection = async () => {
    setLoading(true);
    try {
      const url = isEditing ? `/v1/admin/team/${teamId}/collection/${currentCollectionId}` : `/v1/admin/team/${teamId}/collection`;
      const method = isEditing ? 'patch' : 'post';

      await axiosInstance[method](url, {
        name: collectionName,
        description: collectionDescription,
      });

      setReloadCollections(prev => prev + 1);
      setOpenDialog(false);
      toast.success(`Collection ${isEditing ? 'updated' : 'created'} successfully.`);
    } catch (error) {
      toast.error(`Sorry, your collection couldn't be ${isEditing ? 'updated' : 'created'}. Please try again.`);
    } finally {
      setLoading(false);
      if (isEditing) {
        setIsEditing(false);
        setCurrentCollectionId(null);
      }
    }
  };

  return (
    <SideDrawer title={`${selectedTeam.name} - Collections`}>
      <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
        <Grid item display="flex" alignItems="center">
          <Button
            variant="text"
            onClick={handleBackNavigation}
          >
            <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
          </Button>

          <NavBreadcrumbs
            currentPageTitle="Collections"
            history={[
              {
                title: "Teams",
                path: "/teams"
              },
              {
                title: "Projects",
                path: `/team/${teamId}/projects`
              }
            ]}
          />
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Button
            variant="outlined"
            onClick={handleCreateNewCollection}
            sx={{
              background: 'white',
            }}
          >
            <AddCircleOutlineIcon sx={{ marginRight: '10px' }} /> Create new collection
          </Button>
        </Grid>
      </Grid>
      <CollectionListComponent
        teamId={teamId}
        reload={reloadCollections}
        onEditCollection={handleEditCollection}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? 'Update collection' : 'Create new collection'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={collectionDescription}
            onChange={(e) => setCollectionDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={loading}>Cancel</Button>
          <Button onClick={handleSaveCollection} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </SideDrawer>
  );
}

export default CollectionListPage;
