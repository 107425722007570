import React, { useEffect, useRef, useState } from 'react';
import { useApi } from '../../providers/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../../providers/ProjectsProvider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Box, Button, CircularProgress, Container, Grid, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';

function CollectionListComponent({ teamId, reload, onEditCollection }) {
    const navigate = useNavigate();
    const { axiosInstance } = useApi();
    const { setSelectedCollection } = useProjects();
    const [teamCollections, setTeamCollections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [deleteDialogCollectionId, setDeleteDialogCollectionId] = useState(null);

    // Fetch collections
    const fetchCollections = async (page) => {
        try {
            const response = await axiosInstance.get(`/v1/admin/team/${teamId}/collection`);
            setTeamCollections(response.data)
            setLoading(false);
            setLoaded(true);
        } catch (error) {
            toast.error('Sorry, an error occurred. We were not able to retrieve the list of collections. Please try again later.');
            setLoading(false);
            setLoaded(true);
        }
    };

    useEffect(() => {
        if (!loading && !loaded) {
            setLoading(true);
            fetchCollections();
        }
    }, [loading, loaded]);

    useEffect(() => {
        if (reload !== undefined) {
            setLoaded(false);
        }
    }, [reload]);

    const confirmDelete = async (collectionId) => {
        try {
            const response = await axiosInstance.delete(`/v1/admin/team/${teamId}/collection/${collectionId}`);

            // Remove the deleted collection from the collections list
            setTeamCollections((prevCollections) => prevCollections.filter((collection) => collection._id !== collectionId));

            // TODO: decrement collectionsCount from relevant cached team

            setDeleteDialogCollectionId(null);
            toast.success('Successfully deleted');
        } catch (error) {
            setDeleteDialogCollectionId(null);
            toast.error('Deletion failed');
        }
    };

    const handleViewCollectionEndpoints = (collection) => {
        setSelectedCollection(collection);
        navigate(`/team/${teamId}/collection/${collection._id}/endpoints`);
    };

    return (
        <div>
            {teamCollections.length === 0 && !loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'top',
                        minHeight: '100vh',
                        marginTop: '100px',
                    }}
                >
                    <Container maxWidth="md">
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} textAlign="center">
                                <img
                                    src="/empty-box.png"
                                    alt=""
                                    height={150}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h5">
                                    No collections
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography>
                                    Feel free to create a new collection and it will get listed here.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {teamCollections.map((collection) => (
                        <Grid item xs={6} key={collection._id}>
                            <Card variant="outlined">
                                <CardHeader
                                    title={collection.name}
                                    action={
                                        <div>
                                            <Tooltip
                                                title="View collection"
                                            >
                                                <Button
                                                    aria-label="view collection"
                                                    variant="text"
                                                    sx={{ marginLeft: "auto" }}
                                                    onClick={() => { handleViewCollectionEndpoints(collection) }}
                                                >
                                                    <VisibilityIcon sx={{ marginRight: '10px' }} />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Edit collection details"
                                            >
                                                <Button
                                                    aria-label="edit collection"
                                                    variant="text"
                                                    sx={{ marginLeft: "auto", marginRight: '10px' }}
                                                    onClick={() => onEditCollection(collection)}
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Delete collection"
                                            >
                                                <Button
                                                    aria-label="delete collection"
                                                    variant="text"
                                                    sx={{ marginRight: "auto" }}
                                                    onClick={() => { setDeleteDialogCollectionId(collection._id) }}
                                                >
                                                    <DeleteIcon sx={{ marginRight: '10px' }} />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    }
                                />
                                <CardContent>
                                    {collection.description ? (
                                        <Typography variant="body2" color="text.secondary">
                                            {collection.description}
                                        </Typography>
                                    ) : null}
                                    <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                        Mock endpoints: {collection.mockEndpointsCount}
                                    </Typography>
                                </CardContent>

                                {/* Delete Confirmation Dialog */}
                                <Dialog
                                    open={deleteDialogCollectionId == collection._id}
                                    onClose={() => setDeleteDialogCollectionId(null)}
                                >
                                    <DialogTitle>Confirm Deletion</DialogTitle>
                                    <DialogContent>
                                        Are you sure you want to delete this collection?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setDeleteDialogCollectionId(null)} color="primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => confirmDelete(collection._id)}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
            {(loading) &&
                <Box
                    sx={{ width: '100%', marginTop: '40px' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            }
            <div id="loading" style={{ height: '10px' }}></div>
        </div>
    );
}

export default CollectionListComponent;