import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectCreateUpdateEndpointComponent from '../../components/endpoints/ProjectCreateUpdateEndpointComponent';
import SideDrawer from '../../components/common/SideDrawer';
import { Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useProjects } from '../../providers/ProjectsProvider';
import NavBreadcrumbs from '../../components/common/NavBreadcrumbs';

function ProjectCreateNewEndpointPage() {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const { selectedTeam, selectedDuplicateEndpoint } = useProjects();
    
    const handleBackNavigation = async () => {
        navigate(-1);
    };
    
    return (
        <SideDrawer title='Create Mock Endpoint'>
            <Grid container alignItems="center" sx={{ marginBottom: '30px' }}>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={handleBackNavigation}
                    >
                        <ArrowBackIosIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>

                <NavBreadcrumbs
                    currentPageTitle="Create Endpoint"
                    history={[
                        {
                            title: "Teams",
                            path: "/teams"
                        },
                        {
                            title: "Projects",
                            path: `/team/${selectedTeam._id}/projects`
                        },
                        {
                            title: "Endpoints",
                            path: `/project/${projectId}/endpoints`
                        }
                    ]}
                />
            </Grid>
            <ProjectCreateUpdateEndpointComponent projectId={projectId} duplicateEndpoint={selectedDuplicateEndpoint} />
        </SideDrawer>
    );
}

export default ProjectCreateNewEndpointPage;