import React, { createContext, useContext, useState, useEffect } from 'react';

const ProjectsContext = createContext();

export function useProjects() {
    return useContext(ProjectsContext);
}

export function ProjectsProvider({ children }) {
    const [teams, setTeams] = useState(() => {
        // Load projects from localStorage on component initialization
        const cachedTeams = localStorage.getItem('teams');
        return cachedTeams ? JSON.parse(cachedTeams) : [];
    });

    const [selectedTeam, setSelectedTeam] = useState(() => {
        // Load selectedTeam from localStorage on component initialization
        const cachedSelectedTeam = localStorage.getItem('selectedTeam');
        return cachedSelectedTeam ? JSON.parse(cachedSelectedTeam) : null;
    });

    const [projects, setProjects] = useState(() => {
        // Load projects from localStorage on component initialization
        const cachedProjects = localStorage.getItem('projects');
        return cachedProjects ? JSON.parse(cachedProjects) : [];
    });

    const [selectedProject, setSelectedProject] = useState(() => {
        // Load selectedProject from localStorage on component initialization
        const cachedSelectedProject = localStorage.getItem('selectedProject');
        return cachedSelectedProject ? JSON.parse(cachedSelectedProject) : null;
    });

    const [selectedEndpoint, setSelectedEndpoint] = useState(() => {
        // Load selectedEndpoint from localStorage on component initialization
        const cachedSelectedEndpoint = localStorage.getItem('selectedEndpoint');
        return cachedSelectedEndpoint ? JSON.parse(cachedSelectedEndpoint) : null;
    });

    const [selectedDuplicateEndpoint, setSelectedDuplicateEndpoint] = useState(() => {
        // Load selectedDuplicateEndpoint from localStorage on component initialization
        const cachedSelectedDuplicateEndpoint = localStorage.getItem('selectedDuplicateEndpoint');
        return cachedSelectedDuplicateEndpoint ? JSON.parse(cachedSelectedDuplicateEndpoint) : null;
    });

    const [selectedCollection, setSelectedCollection] = useState(() => {
        // Load selectedEndpoint from localStorage on component initialization
        const cachedSelectedCollection = localStorage.getItem('selectedCollection');
        return cachedSelectedCollection ? JSON.parse(cachedSelectedCollection) : null;
    });

    // Save selectedTeam, projects and selectedEndpoint to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('selectedTeam', JSON.stringify(selectedTeam));
    }, [selectedTeam]);

    useEffect(() => {
        localStorage.setItem('projects', JSON.stringify(projects));
    }, [projects]);

    useEffect(() => {
        localStorage.setItem('selectedEndpoint', JSON.stringify(selectedEndpoint));
    }, [selectedEndpoint]);

    useEffect(() => {
        localStorage.setItem('selectedCollection', JSON.stringify(selectedCollection));
    }, [selectedCollection]);

    const getProjectById = (projectId) => {
        return projects.find((project) => project.id === projectId);
    };

    const updateProjectRecordMode = (projectId, isEnabled) => {
        projects.find((project) => project.id === projectId).isRecordModeEnabled = isEnabled;
        localStorage.setItem('projects', JSON.stringify(projects));
    };

    return (
        <ProjectsContext.Provider
            value={{
                teams,
                setTeams,
                selectedTeam,
                setSelectedTeam,
                projects,
                setProjects,
                selectedProject,
                setSelectedProject,
                selectedEndpoint,
                setSelectedEndpoint,
                selectedCollection,
                setSelectedCollection,
                selectedDuplicateEndpoint,
                setSelectedDuplicateEndpoint,
                getProjectById,
                updateProjectRecordMode,
            }}
        >
            {children}
        </ProjectsContext.Provider>
    );
}
